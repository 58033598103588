.sidebar {
    position: fixed;
    width: 100%;
    background:white;
    height: 56px;
    top: 0;
    left: 0;
  }
  
  .sidebarnav {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
  }
  
  .sidebarlogo {
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
  
  .sidebarlogo a{
    color: #ff6969;
    text-decoration: none;
    font-size: 1.5rem;
  }
  
  .sidebaritems ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .sidebaritems li{
    padding: 0 0.5rem;
  }
  
  .sidebaritems a{
    color: #ff6969;
    text-decoration: none;
  }
  
  .spacer {
    flex: 1;
  }

  .mob-logo {
    float: right;
    height: 27px;
  }
  
  
