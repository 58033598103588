body {
  font-family: IBM Plex Sans;
}
.login-wrap {
  width: 100%;
  height: auto;
  margin: 0 auto;
}
/*............login/header.............  */
.login-header {
  width: 100%;
  height: 70px;
  box-shadow: 0 1px 0 #e4e4e4;
  overflow: hidden;
}
.login-logo {
  width: 10%;
  height: 70px;
  padding: 9px;
  margin: 1%;
}
.login-mai-logo {
  font-family: "IBM Plex Sans";
  height: 20px;
  font-size: 16px;
  font-weight: 600;
  margin: 0px 15px;
}
/*............login/main heading.............  */
.mai-login-main-back {
  width: 100%;
  height: auto;
  margin: 2% auto; 
  margin-left: 5%;
}
.mai-heading {
  width: 100%;
  height: 92px;
  margin: 0 auto;
  font-size: 75px;
  color: #515c6f;
  text-align: center;
  font-family: IBM Plex Sans, Bold;
  line-height: 92px;
  font-weight: 700;
}
/*............login/forgott.............  */

.signin-link {
  width: 25%;
  display: inline-block;
  text-align: center;
}
.signin-link a {
  font-size: 30px;
  color: #515c6f;
  font-family: "IBM Plex Sans", Bold;
  font-weight: 700;
}
.signin-link a:hover {
  text-decoration: none;
  cursor: pointer;
}
.mai-login-forgot-n {
  width: 400px;
  /* height: 50px; */
  margin: 20px auto;
  text-align: center;
}
.mai-login-link-n {
  /* width: 40%; */
  display: inline;
  /* vertical-align: middle; */
}
.mai-login-link-n a {
  font-size: 25px;
  color: #515c6f;
  font-family: "IBM Plex Sans", Bold;
  font-weight: 700;
  padding: 8px;
}
.mai-login-link-n a:hover {
  text-decoration: none;
  cursor: pointer;
}
.mai-forgot-link-n {
  /* width: 59%; */
  display: inline;
  /* vertical-align: middle; */
}
.mai-forgot-link-n a {
  font-size: 25px;
  color: #e7eaf0;
  font-family: "IBM Plex Sans", Bold;
  font-weight: 600;
  padding: 8px;
}
.mai-forgot-link-n a:hover {
  text-decoration: none;
  cursor: pointer;
}
/*............login/input.............  */
.login-input {
  width: 380px;
  height: auto;
  margin: 0 auto;
  margin-top: 35px;
}
.mai-login-competitors-container {
  width: 315px;
  color: #fff;
  height: 60px;
  background: #fff;
  border-radius: 10px 10px 0 0;
  border: 0;
  box-shadow: 0 5px 10px #e7eaf0;
  color: #515c6f;
  font-size: 14px;
  position: relative;
  margin: 0 auto;
  
}
.pass-border {
  border-radius: 0 0 10px 10px !important;
}
.error-text{
  text-align: center;
  margin: 8px auto;
  display: flex;
  justify-content: center;
}
.mai-login-competitors-container .login-left-icon {
  display: inline-block;
  width: 50px;
  height: 20px;
  text-align: center;
  margin-top: 15px;
}
.mai-login-competitors-container .login-left-icon img {
  width: 20px;
}
.login-right-icon {
  display: inline-block;
  height: 100%;
  width: calc(100% - 70px);
  position: absolute;
}
.web-address-input-container input::placeholder {
  font-weight: 400;
}
/*............login/login-button.............  */
.login-button {
  width: 330px;
  height: 55px;
  margin: 15px auto;
}
.mai-login-btn-compare {
  width: 320px;
  color: #fff;
  height: 46px;
  background: #ff6969;
  border-radius: 30px;
  border: 0;
  box-shadow: 0 5px 10px #ff696964;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  position: absolute;
}
.mai-login-btn-compare:disabled {
  cursor: not-allowed;
  background: #ff8787;
}
.mai-login-btn-compare .right-icon-container {
  width: 28px;
  height: 28px;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff6969;
  background: rgb(255, 255, 255);
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 30px;
}
.mai-login-btn-compare .right-icon-container .spinner {
  color: #ff6969;
}
/*
.right-icon-container img {
  vertical-align: middle;
  border-style: none;
  padding-top: 4px;
}
*/
.mai-login-below-text {
  width: 30%;
  margin: 0 auto;
  margin-top: 5px;
  text-align: center;
  font-size: 13px;
  color: rgb(179, 165, 165);
  font-weight: 600;
}
/*............login/google-button.............  */
.social-button-back {
  width: 330px;
  height: 50px;
  margin: 15px auto;
  color: #fff;
}
.social-button-back .li-button {
  text-transform: none;
  }
.social-button-back .spinner {
  color: #fff;
}
.mai-google-button {
  width: 320px;
  color: #fff;
  height: 46px;
  background: #4285f4;
  border-radius: 30px;
  border: 0;
  box-shadow: 0 5px 10px #4285f4;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
}
/*............login/facebook-button.............  */
.mai-li-button {
  width: 320px;
  color: #fff;
  height: 46px;
  background: #3a5997;
  border-radius: 30px;
  border: 0;
  box-shadow: 0 5px 10px #3a5997;
  color: #fff;
  font-size: 14px;
  position: absolute;
  text-transform: uppercase;
  font-weight: 600;
}
/*............login/swipe-option.............  */
.swipe-back {
  width: 30%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 25%;
}
.swipe {
  font-size: 12px;
  color: #515c6f;
  text-align: center;
}
.swipe-back a {
  color: #ff6969;
  font-size: 12px;
}
.swipe-back a:hover {
  text-decoration: none;
}

/*............Mobile-view - login/header.............  */
@media screen and (max-width: 1250px) {
  .login-wrap {
    width: 120%;
    height: auto;
    margin: 0 auto;
    border-bottom: none;
    background-color: #f5f6f8;
    text-align: left;
    position: relative;
    left: -20px;
  }
  /*............login/header.............  */
  .login-header {
    display: none;
  }
  /*............login/Main heading.............  */
  .mai-heaing {
    display: none;
  }
  /*............login/forgot.............  */
  .login-forgot {
    width: 100%;
    margin-bottom: 20%;
  }
  .pre-footer-container {
    display: none;
  }
  .footer-container {
    display: none;
  }
  /*............login/input.............  */
  .mai-login-input {
    width: 80%;
    margin: 20px auto;
    margin-left: 8%;
  }
  /*............login/button.............  */
  .below-text {
    width: 60%;
    margin: 6px auto;
    text-align: center;
  }
  .mai-login-below-text {
    margin-top: 0%;
  }
  .swipe-back {
    width: 56%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 0%;
    margin-top: 25%;
  }
}
