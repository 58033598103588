@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");

.body{
}
.web-address-input::placeholder {
    text-transform: uppercase;
    color: #515C6F;
    font-weight: 100;
    font-size: 15px;
}
/*......breadcrumbs.......*/ 

ul.breadcrumb {
    padding: 0px 0px;
    list-style: none;
    margin-bottom: 0;
}
ul.breadcrumb li {
    display: inline;
    font-size: 12px;
}
ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
}
ul.breadcrumb li a {
    color: #000;
    text-decoration: none;
}
ul.breadcrumb li a:hover {
    color: #000;
}
.breadcrumb {
    background-color: transparent;
    width: 770px;
}
.priority ul {
    list-style: none;
    margin-top: 9px;
}
.just-show ul {
    list-style: none;
    margin-top: 9px;
}
.status ul {
    list-style: none;
    margin-top: 9px;
}
.checkbox a {
    color:#0D0E10;
    font-size: 11px; 
    font-family: IBM Plex Sans, SemiBold; 
    color:#0D0E10;
}
.checkbox a:hover {
    text-decoration: none;
}

/* Pre-Footer and Footer Items CSS - Written on Aug 18, 2019 by Ayush */
.pre-footer-container {
 width: 100%;
 height: 120px;
 margin: 0;
 padding: 38px 70px;
}
.pre-foot-mai-logo {
 display: inline-block;
 width: 7%;
 height: 40px;
 background: #fff;
 margin: 0;
 line-height: 40px;
 font-size: 32px;
 font-weight: 900;
 padding: 0;
}
.pre-foot-mai-links {
 display: inline-block;
 width: 55%;
 height: 40px;
 line-height: 40px;
 margin: 0 75px;
 padding: 0;
 text-align: right;
}
.pre-foot-item {
 color: #666666;
 font-size: 16px;
 line-height: 40px;
 display: inline;
 margin: 0px 5px;
}
.pre-footer-links {
 color: #666666;
}
.pre-foot-mai-copyright {
 display: inline-block;
 width: 20%;
 height: 40px;
 margin: 0;
 line-height: 40px;
 margin: 0px 5px;
 padding: 0;
 text-align: right;
}
.pre-foot-copy {
 color: #6e6e6e;
 font-size: 10px;
 line-height: 40px;
 margin: 0px 5px;
}

/* Footer container  */
.footer-container {
 width: 100%;
 height: 367px;
 background: #f8f9fb;
 margin: 0;
 padding: 80px;
}
.footer-seo-items-container {
 width: 15%;
 height: 200px;
}
.footer-items-heading {
 width: 100%;
 height: 20px;
 font-size: 18px;
 font-family: "Montserrat", sans-serif;
 color: #666666;
 font-weight: bold;
}
.footer-items {
 margin-top: 20px; 
}
.footer-item {
 width: 100%;
 height: 20px;
 margin: 10px 0;
}
.footer-item a {
 font-family: "Source Sans Pro", sans-serif;
 font-weight: 500;
 font-size: 16px;
 color: #666666;
}
.footer-local-items-container {
 width: 18%;
 height: 200px;
 margin-left: 55px;
}

.footer-marketing-items-container {
    width: 18%;
    height: 200px;
    margin-left: 55px;
}

.footer-separation {
 width: 0px;
 height: 200px;
 border: 1px solid #70707052;
 border-radius: 4px;
 margin-left: 45px;
}
.footer-subscribe-items-container {
 width: 25%;
 height: 200px;
 margin-left: 40px;
 margin-top: 10px;
}
.subscribe-blog-container {
 width: 100%;
 height: 20px;
 font-size: 16px;
 font-family: "Montserrat", sans-serif;
 color: #666666;
 font-weight: 300;
}
.subscribe-body-container {
 position: relative;
 margin-top: 20px;
 width: 100%;
 height: 20px;
 font-size: 14px;
 font-family: "Montserrat", sans-serif;
 color: #666666;
 font-weight: 200;
}
.subscribe-btn {
 margin-top: 43px;
 width: 240px;
 color: #fff;
 height: 46px;
 background: #ff6969;
 border-radius: 30px;
 border: 0;
 box-shadow: 0 5px 10px #ff696964;
 color: #fff;
 font-size: 14px;
 text-transform: uppercase;
}
/* -------------------------------------------------------------------------------------------------------------------- */

/* Footer css */

.header {width: 100%; height: 70px; padding: 16px;}
.row {margin: 0;}
.mai-logo-row {width: 10%; height: 38px; padding: 9px; margin: 0; border-right: 1px solid #00000010;}
.header-mai-logo {font-family: 'Roboto', sans-serif; height: 20px; font-size: 16px; font-weight: 500; margin: 0px 15px;}
.separator {width: 1px; height: 38px; background: #fff;}

.btn btn-secondary dropdown-toggle {border: none; background: #fff; color: #3E3F42; font-family: 'Roboto', sans-serif; font-size: 16px; font-weight: 600;}
.login-btn {float: right;}
.button2 {width: 110px; color: #fff; height: 38px; background: #ff6969; border-radius: 4px; border: 0;}
