.task-list {
  display: flex;
  width: 100%;
}

.checkbox-div {
  margin-top: 25px;
}

.tasklist-div {
  width: 100%;
  padding: 5px 10px 5px 10px;
}
