body {
    font-family:IBM Plex Sans;
 }   
.home-wrap {
    width:100%;
    height:auto;
    margin: 0 auto;
}
/*............Home/header.............  */
.home-header {
    width:100%;
    height:70px;
    box-shadow: 0 1px 0 #E4E4E4; 
    overflow: hidden;
}
.error-text{
    text-align: center;
    margin: 8px auto;
    display: flex;
    justify-content: center;
}
.home-logo {
    width: 10%;
    height: 70px;
    padding: 9px;
    margin: 1%;
}
.home-mai-logo {
    font-family: "IBM Plex Sans";
    height: 20px;
    font-size: 16px;
    font-weight: 600;
    margin: 0px 15px;
}
/*............Home/main heading.............  */
.home-main-back {
    width: 100%;
    height: auto;
    margin: 10% auto;
}
.mai-heading {
    width:100%;
    height:92px;
    margin: 0 auto;
    font-size: 75px;
    color:#515C6F;
    text-align: center;
    font-family: IBM Plex Sans, Bold;
    line-height: 92px;
    font-weight: 700;
}
/*............Home/content.............  */
.home-content {
    width:35%;
    height:auto;
    margin:11px auto;
    font-size: 20px;
    color:#515C6F;
    text-align: center;
    font-family: IBM Plex Sans;
}
/*............Home/input.............  */
.home-input {
    width:50%;
    height:65px;
    margin:20px auto;
}
.home-competitors-container {
    width: 325px;
    color: #fff;
    height: 60px;
    background: #fff;
    border-radius: 10px;
    border: 0;
    box-shadow: 0 5px 10px #e7eaf0;
    color: #515c6f;
    font-size: 14px;
    position: relative;
    margin: 0 auto;
}
.home-competitors-container .home-left-icon {
    display: inline-block;
    width: 30px;
    height: 20px;
    text-align: center;
    margin-top: 19px;
    margin-left: 10px;
}
.home-competitors-container .home-left-icon img {
    width: 20px;
}
.home-right-icon {
    display: inline-block;
    height: 100%;
    width: calc(100% - 70px);
    position: absolute;
}
.web-address-input-container input::placeholder {
    font-weight: 400;
}
/*............Home/button.............  */ 
.home-button {
    width:330px;
    height:55px;
    margin:0 auto;
}
.home-btn-compare {
    width: 330px;
    color: #fff;
    height: 46px;
    background: #ff6969;
    border-radius: 30px;
    border: 0;
    box-shadow: 0 5px 10px #ff696964;
    color: #fff;
    font-size: 14px;
    position: absolute;
    text-transform: uppercase;
    font-weight:600;
}
.home-btn-compare:disabled {
    cursor: not-allowed;
    background: #ff8787;
}
.home-btn-compare .right-icon-container {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff6969;
    background: rgb(255, 255, 255);
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 30px;
}
.home-btn-compare .right-icon-container .spinner {
    color: #ff6969;
}
.spinner-alt {
    color: #fff;
}
/*
.right-icon-container img {
    vertical-align: middle;
    border-style: none;
    padding-top: 4px;
}
*/
.below-text {
    width:30%;
    margin:0 auto;
    text-align: center;
}
.below-text a {
    font-size: 16px;
    color:#FF6969;
    text-decoration: none;
}
.below-text a:hover {
    color:#FF6969;
    text-decoration: none;
}
.mob-view {
    display: none;
}
.desktop-view {
    display: block;
}
/*............Mobile-view - Home/header.............  */
@media screen and (max-width: 1250px) {
    .mob-view {
        display: block;
    }
    .desktop-view {
        display: none;
    }
    .home-wrap {
        width:100%;
        height:auto;
        margin: 0 auto;
        border-bottom: none;
        /* background-color:#515c6f;  */
    }
/*............Home/header.............  */
    .home-header {
        width:100%;
        height:70px;
        overflow: hidden;
        background-color: #fff;
        box-shadow: none;
        margin-top: 20px;
    }
    .home-header button {
        float:right;
    }
    .home-header button img {
        width:18px;        
    }
/*............Home/Main heading.............  */
    .home-main-back {
        width:100%;
        height:470px;
        margin: 10% auto;
    }
    .mai-heaing {
        width:55%;
    }
/*............Home/content.............  */
    .home-content {
        width: 380px; 
        /* margin-bottom: 20%; */
    }
/*............Home/input.............  */
    .home-input {
        width: 88%; 
        margin: 10px auto;
    }
/*............Home/button.............  */
    .below-text {
        width: 60%;
        margin: 6px auto;
        text-align: center;
    }
}


/*...............Main/body.............*/
.acc-body{
    margin: 2%;

}
.Accounts_Project_table{
    width:80%;
}
.Accounts_Project_td{
    margin: 2px;
    padding: 2px;
}