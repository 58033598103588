.side-drawer {
    height: 100%;
    background: white;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 200;
}

.side-drawer ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    float: left;
}

.side-drawer li {
    margin: 0.5rem 0;
    margin-bottom: 1.5rem;
    display:inline-block;
}

.mob-logo-sidebar {
    height: 35px;
}

.moblogo {
    margin:0 3rem; 
    margin-top: 2rem;
    margin-bottom: 1.6rem;
}

.mob-logout {
    color: #515C6F;
    text-decoration: none;
    font-size: 1.5rem;
}
