body {
    font-family:IBM Plex Sans;
}
.dash-main {
    width: 100%;
    /* height:auto; */
    height:1350px;
    background: #FBFBFD; 
}
.container {
    max-width: 1290px !important;
    /* background-color: aqua; */
}

/*......section/ left side.......*/
.dash-leftWrapper {
    width:100%;
    height:auto;
    overflow: hidden;
}
.frame-back {
    width:96%;
    margin: 10% auto;
}
.image-frame-inside {
    width:96%;
    position: relative;
    left:1%;
}
.image-frame {
    width:100%;
    position: absolute;
    left:0;
    bottom: 37%;
}

/*......aside/ right side.......*/  
.dash-rightWrapper {
    width:90%;
    height:965px;
    margin-left: 10%;
}
/*......aside/ right side-header.......*/
.dash-rightHeader {
    width:100%;
    height:55px;
    margin-bottom: 7px;
}
.code-image {
    width: 73px;
    height:54px;
    display: inline-block;
    overflow: hidden;
}
.code-image img {
    width:72px;
}
.code-text {
    width: 265px;
    height:54px;
    display: inline-block;
    color:#707070;
    font-size: 40px;
    font-weight: 400;
    font-family: "IBM Plex Sans", Regular;
    overflow: hidden;
    line-height: 45px;
}

/*......aside/ right side-bar.......*/
.dash-rightBar {
    width:100%;
    height:71px;
    margin-top: 8px;
}
.crawl-percent {
    font-size: 14px;
    font-family: "IBM Plex Sans", Regular;
}
.crawl-time {
    font-size: 14px;
    font-family: "IBM Plex Sans", Regular;
    margin-left: 3%;
}
.progress {
    border-radius:4px;
    background-color: #fff;
    border:1px solid #707070; 
}
.progress-bar {
    background-color: #FF6969;
}

/*......aside/ right side-score.......*/
.dash-rightScore {
    width:100%;
    height:auto;
    margin-top: 30px;
    margin-bottom: 11%;
}
.seo-score {
    width: 99%;
    height: 146px;
    background-image: url("../image/Mask Group-blue.png");
    background-repeat: no-repeat;
    padding: 1px;
    margin: -1px;
}
.content-score {
    width: 99%;
    height: 146px;
    background-image: url("../image/Mask Group-pink.png");
    background-repeat: no-repeat;
    padding: 1px;
    margin: -1px;
    margin-top: 22px;
}
.score-detail {
    width: 73%;
    height: 110px;
    /* background-color: #FF6969; */
    margin: 20px 0 0 35px;
}
.score-label {
    font-size: 14px;
    color: #fff;
    font-weight: 800;
}
.score-count {
    height: 65px;
    font-size: 57px;
    color: #fff;
    line-height: 55px;
    font-family: "IBM Plex Sans", Bold;
}
.score-count small {
    color: #DEDFE1;
    font-size: 45px; 
    font-weight: 300;
}
.score-message {
    font-size: 14px;
    color: #DEDFE1;
}
/*......aside/ right side-connect-app.......*/
.dash-rightConnect {
    width:100%;
    height:auto;
    margin-bottom: 9%;
}
.app-box {
    margin-right:1%;
} 

/*......aside/ right side-code.......*/
.dash-rightCodes {
    width:100%;
    height:auto;
    margin-bottom: 40px;
}
.rightCodes-head {
    height: 18px;
    width: calc(100%);
    color: #000000;
    font-size: 14px;
    font-family: "IBM Plex Sans";
    margin-bottom: 16px;
    font-weight:600;
}
.dash-view {
    color:#3F51B5;
    font-size:12px;
    font-weight: 600;
    float: right;
}
.dash-view:hover {
    text-decoration: none;
}
.dash-task-item {
    flex: 7%;
    max-width: 7%;
    margin-left: 4%;
}
.dash-task-name {
    flex: 45%;
    max-width: 45%;
    word-break: break-word;
    font-size: 16px;
    font-weight: 700;
    font-family: "IBM Plex Sans";
    color: #0d0e10;
    overflow:hidden;
    text-overflow: ellipsis; 
    white-space: nowrap;
    margin-right:1%;
}
.dash-task-name a {
    color:#0d0e10;
    text-decoration: none;
}
.dash-task-significance {
    flex: 14%;
    max-width: 14%;
    background: #ff6969;
    height: 25px;
    border-radius: 14px;
    font-size: 13px;
    color: #fff;
    font-family: "IBM Plex Sans";
    text-align: center;
    line-height: 25px;
    margin-right:3%;
}
.green {
    background: #7ed321;
}
.dash-assigned-container {
    flex: 20%;
    max-width: 20%;
    position: relative;
}
/*......aside/ right side-header.......*/
.dash-rightCompetitior {
    width:100%;
    height:auto;
    margin-bottom: 15px;
}
.dash-competitors-heading{
    font-weight: 700;
    font-size: 15px;
    color: #0D0E10;
}
.compare-competitors-container {
    margin-top: 10px;
    width: 450px;
    height: 55px;
}
.web-address-label {
    font-weight:700;
}
.web-address-input-container input::placeholder {
    font-weight: 500;
}
/*......aside/ right side-header.......*/
.dash-rightbutton {
    width:100%;
    height:auto;
}
.dash-btn-compare {
    width: 450px;
    color: #fff;
    height: 46px;
    background: #ff6969;
    border-radius: 30px;
    border: 0;
    box-shadow: 0 5px 10px #ff696964;
    color: #fff;
    font-size: 14px;
    /* position: absolute; */
    position: relative;
    text-transform: uppercase;
}
.dash-btn-compare .right-icon-container {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgb(255, 255, 255);
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 30px;
}
.dash-btn-compare .right-icon-container img {
    margin-top: 3px;
}
.desktop-view {
    display: block !important;
}
.mob-view {
    display: none !important;
}
/*..................For Mobile View......................  */

@media screen and (max-width: 1250px) {
    .desktop-view {
        display: none !important;
    }
    .mob-view {
        display: block !important;
        padding: 0px;
    }
    .back-button-div {
        display: inline-block;
        position: relative;
        top: -5px;
        margin: 0px 8px;
    }
    .mob-dash-top-wrap {
        width:100%;
        height:330px;
        border-radius: 0 0 0 80px;
        background-color: #fff;
        /* border:1px solid #998FA2; */
        position: relative;
        z-index: 2;
        overflow:hidden;
    }
    .mob-dash-head {
        width:100%;
        height: 55px;
        margin:10px auto 0 auto; 
    }
    .mob-dash-heading-container {
        width: 100%;
        /* float: left; */
        line-height: 32px;
        justify-content: center;
        justify-items: center;
        align-items: center;
        align-content: center;
        vertical-align: middle;
    }
    .mob-page-heading {
        display: inline-block;
        font-family: "IBM Plex Sans";
        font-size: 26px;
        font-weight: 800;
        color:#515C6F;
    }
    .setting-icon {
        float: right;
        margin: 8px 0;
        display: grid;
    }
    .mob-dash-progress {
        width: 100%;
        padding: 0 35px;
        height: 50px;
        margin: 0 auto;
    }
    .mob-linear-progress-bar {
        width:50%;
        height:10px;
        background-color: #FF6969;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        transition: width .6s ease; 
    }
    .mob-linear-progress {
        height:10px;
        border-radius:0px;
        background-color: #D4D6DC;
        border: 1px solid #707070;
        display: flex;
        overflow: hidden;
        font-size: .75rem;
    }
/* .......circle progress bar......... */
    .circle-bar {
        width:100%;
        height:190px;
        padding: 25px;
    }
    .col-4-custom {
        width:50%;
        margin-top: 6%;
    }
    .col-8-custom {
        width:36%;
    }
    .page-analyzed {
        width: 67%;
    }
    .total-progress {
        width: 62%;
        margin-top: 7px;
    }
    .label-custom {
        font-size: 9px;
        line-height: 10px;
        color: #929292;
        margin-bottom: 0;
    }
    .span-custom {
        font-size: 27px;
        color: #707070;
        font-weight: 700;
    }
    .total-span-custom {
        font-size: 27px;
        color: #FF6969;
        font-weight: 700;
    }
    .mob-circle-progress {
        width: 172px;
        height: 172px;
        background: none;
        position: relative;
    }
      
    .mob-circle-progress::after {
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 6px solid #eee;
        position: absolute;
        top: 0;
        left: 0;
    }
      
    .mob-circle-progress>span {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        z-index: 1;
    }
      
    .mob-circle-progress .mob-circle-progress-left {
        left: 0;
    }
      
    .mob-circle-progress .mob-circle-progress-bar {
        width: 100%;
        height: 100%;
        background: none;
        border-width: 6px;
        border-style: solid;
        position: absolute;
        top: 0;
    }
      
    .mob-circle-progress .mob-circle-progress-left .mob-circle-progress-bar {
        left: 100%;
        border-top-right-radius: 80px;
        border-bottom-right-radius: 80px;
        border-left: 0;
        -webkit-transform-origin: center left;
        transform-origin: center left;
    }
      
    .mob-circle-progress .mob-circle-progress-right {
        right: 0;
    }
      
    .mob-circle-progress .mob-circle-progress-right .mob-circle-progress-bar {
        left: -100%;
        border-top-left-radius: 80px;
        border-bottom-left-radius: 80px;
        border-right: 0;
        -webkit-transform-origin: center right;
        transform-origin: center right;
    }
      
    .mob-circle-progress .mob-circle-progress-value {
        position: absolute;
        top: 0;
        left: 0;
    }
/*.........Mobile View/seo............*/
    .mob-dash-seo-wrap {
        width:100%;
        /* height:230px; */
        border-radius: 0 0 0 80px;
        background-image: url("../image/mob-blue.svg");
        position: relative;
        margin-top: -20%;
        z-index: 1;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .score-detail {
        margin: 30% 0 5% 15%;
        height: auto;
    }
    .score-detail img {
        float: right;
        margin-top: 3%;
    }
/*.........Mobile View/content............*/  
    .mob-dash-content-wrap {
        width:100%;
        /* height:230px; */
        border-radius: 0 0 0 80px;
        background-image: url("../image/mob-pink.svg");
        margin-top: -20%;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: cover;
    }
/*.........Mobile View/task............*/
    .mob-dash-task-wrap {
        width:92%;
        height:auto;
        margin:5% auto;
        
    }
    .mob-dash-task {
        width: 92%;
        margin: 0 auto;
        margin-top: 8%;
    }
    .mob-dash-task-head{
        font-family: "IBM Plex Sans";
        font-size: 14px;
        color: #0d0e10;
        font-weight: 800;
        display: inline-block;
    }
    .opportunitie {
        width:135px;
        height:25px;
        background-color: #EFF0F1;
        display: inline-block;
        float:right;
        border-radius:14px;
        font-size:13px;
        color:gray;
        line-height: 12px;

    }
    .mob-dash-task-container {
        width: 95%;
        height: 290px;
        margin: 0 auto;
    }
    .unassigned-sub {
        color:#B5B5B6;
        font-weight:8px;
    }
    
/*.........Mobile View/connect............*/
    .mob-dash-connect-wrap {
        width:90%;
        height:auto;
        margin:0 auto;
    }
    .mob-dash-view-all {
        display: inline-block;
        float:right;
        font-size:14px;
        font-family: "IBM Plex Sans";
        display: inline-block;
    }
    .mob-dash-view-all a {
        color:#808080;
        text-decoration: none;
    }
    .app-box-connected {
        display: inline-block;
        width: 150px;
        height: 118px;
        background: #ffffff;
        border-radius: 8px;
        box-shadow: 0px 3px 6px #00000042;
        margin-right: 5%;
        overflow: hidden;
    }
    .mob-dash-app-base-text {
        width: 85%;
        color: #000000;
        font-size: 14px;
        margin-left: 22px;
    }
    .app-black-base-text-status {
        width:66%;
        color: #7ED321;
        font-size: 12px;
    }
/*.........Mobile View/competitors............*/    
    .mob-dash-competitor-wrap {
        width:90%;
        height:auto;
        margin:5% auto;
    }
    .wesite-container {
        margin-left:15px;
    }
    .add-competitor-button {
        margin-left:0px !important;
    }
    .your-competitor-container {
        margin-left: 0px !important;
        display: inline-block;
    }
    .appButton-right-icon {
        background: #fff;
        width: 28px;
        height: 28px;
        border: none;
        border-radius: 30px;
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .appButton-right-icon img {
        width: 7px;
        margin-bottom: 0px !important;
    }
}