.crawl-progress-root {
  position: fixed;
  z-index: 2147483647;
  width: 400px;
  height: 475px;
  right: 0;
  bottom: -400px;
  transition: 100ms linear all;
}

.crawl-progress-container {
  padding: 25px;
  padding-top: 15px;
  height: 75px;
  background-color: #505C6E;
  border-top-left-radius: 16px;
}

.crawl-progress-header {
  display: flex;
  justify-content: space-between;
  color: #FFF;
}

.crawl-progress-body {
  background-color: #fff;
  height: 400px;
  border: 1px solid #505C6E;
  padding: 10px;
  font-size: 0.9rem;
  overflow: scroll;
  padding-bottom: 20px;
}

.crawl-progress-header-progress {
  font-size: 1.2rem;
}

.crawl-progress-header-toggle {
  font-size: 1rem;
  cursor: pointer;
  text-decoration: underline;
}

.crawl-progress-bars {
  display: flex;
  position: relative;
  margin-top: 10px;
}

.ui.progress {
  background: rgba(0, 0, 0, 0);
}

.ui.progress .bar {
  background: #fff;
  position: absolute;
  border-radius: 0px;
  font-size: 0.9rem;
  padding: 1px;
  padding-left: 5px;
}

.ui.progress>.label {
  all: initial;
  display: none;
  position: absolute;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 0.8rem;
  padding-left: 5px;
}

.ui.list>.item>.content {
  word-wrap: break-word;
}

@media screen and (max-width: 1250px) {
  .crawl-progress-root {
    width: 100%;
  }
  
  .crawl-progress-container {
    border-top-left-radius: 0px;
  }
}