@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i&display=swap");

.task-significance1 {
  flex: 10%;
  max-width: 7%;
  background: #7ed321;
  height: 25px;
  border-radius: 14px;
  font-size: 13px;
  color: #fff;
  font-family: "IBM Plex Sans";
  text-align: center;
  line-height: 25px;
}
.web-address-input::placeholder {
  text-transform: uppercase;
  color: #515c6f;
  font-weight: 100;
  font-size: 15px;
}
/*......breadcrumbs.......*/
.des-all-tasks {
  width: 100%;
  height: 70px;
  padding: 16px;
}
.des-all-tasks .bar {
  display: flex;
  justify-content: space-between;
}
.mai-logo .mai-logo {
  width: 10%;
  height: 38px;
  margin: 0;
  margin-top: -5px !important;
  border-right: 1px solid #00000010;
}
.mai-logo-img {
  margin-top: -15px;
  max-width: 150px;
  height: auto;
}
.header-mai-logo {
  font-family: "Roboto", sans-serif;
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  margin: -21px 34px !important;
}
.separator {
  width: 1px;
  height: 38px;
  background: #fff;
}
.top-dropdown {
  border: none;
  background: #fff;
  color: #3e3f42;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.user-btn button {
  float: right;
  width: 110px;
  color: #fff;
  height: 38px;
  background: #ff6969;
  border-radius: 20px;
  border: 0;
}

.main-div {
  margin-top: 73px;
  margin-left: 50px;
  width: 95%;
}
.header-main {
  height: 80px;
  width: 100%;
}

ul.breadcrumb {
  padding: 0px 0px;
  list-style: none;
  margin-bottom: 0;
}
ul.breadcrumb li {
  display: inline;
  font-size: 12px;
}
ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}
ul.breadcrumb li a {
  color: #000;
  text-decoration: none;
}
ul.breadcrumb li a:hover {
  color: #000;
}
.breadcrumb {
  background-color: transparent;
}

.page-head {
  font-size: 30px;
  color: #515c6f;
  font-weight: 700;
  font-family: "IBM Plex Sans";
}
.main-wrapper {
  margin: 30px 0 0 0;
}
.right-container {
  width: 300px;
  height: 100%;
  background: white;
}

.urls {
  width: 190px;
  height: 200px;
  background: white;
  margin-top: 15px;
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  color: #0d0e10;
}
.checkbox a {
  color: #0d0e10;
  font-size: 11px;
  font-family: "IBM Plex Sans";
  flex: 75%;
  max-width: 75%;
}
.checkbox a:hover {
  text-decoration: none;
}
.heading-box {
  font-family: "IBM Plex Sans";
  font-size: 14px;
  color: #0d0e10;
  font-weight: 800;
}
input:focus {
  box-shadow: none;
  outline: none;
}
button:focus {
  outline: none;
  box-shadow: none;
}
.checkbox {
  display: flex;
  margin-top: 9px;
}
.checkboxInput-top {
  font-size: 11px;
  font-family: "IBM Plex Sans";
  flex: 10%;
  max-width: 10%;
  margin-top: 2px;
}
.checkbox-custom a {
  color: #ff6969;
}
.checkboxInput {
  flex: 10%;
  max-width: 10%;
  margin-top: 2px;
}
.checkbox small {
  font-size: 8px;
  font-family: "IBM Plex Sans";
  color: #0d0e10;
}
.checkbox-custom small {
  font-size: 8px;
  font-family: "IBM Plex Sans";
  color: #ff6969;
}
.assigned-to {
  width: 190px;
  height: 95px;
  background: white;
  margin-top: 15px;
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  color: #0d0e10;
}
.created-by {
  width: 190px;
  height: 158px;
  background: white;
  margin-top: 15px;
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  color: #0d0e10;
}

.tasks-for-website {
  height: 18px;
  width: calc(100%);
  background: white;
  color: #ff6969;
  font-size: 14px;
  font-family: "IBM Plex Sans", "sans serif";
}

.main-tasks-container {
  width: calc(100% - 300px);
}

.tasks-wrapper {
  width: 100%;
}

.task-container {
  width: 100%;
  height: 70px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 12px #d3d3d3;
  margin-top: 7px;
  display: flex;
  align-items: center;
}

.task-item {
  flex: 3%;
  max-width: 3%;
  margin-left: 2%;
}

/* .task-name {
  flex: 25%;
  max-width: 25%;
  margin-right: 25px;
  font-size: 16px;
  font-weight: 700;
  font-family: "IBM Plex Sans";
  color: #0d0e10;
} */

.task-significance {
  flex: 10%;
  max-width: 7%;
  height: 25px;
  border-radius: 14px;
  font-size: 13px;
  color: #fff;
  font-family: "IBM Plex Sans";
  text-align: center;
  line-height: 25px;
}

.task-significance.green {
  background: #7ed321;
}

.pages-affected {
  flex: 15%;
  max-width: 15%;
  color: #515c6f;
  font-size: 16px;
  text-align: center;
}

/* .traffic-affected {
  flex: 28%;
  max-width: 28%;
  font-size: 16px;
  font-family: "IBM Plex Sans";
  color: #515c6f;
} */

.message-counter-box {
  flex: 5%;
  max-width: 5%;
}

.assigned-container {
  flex: 10%;
  max-width: 10%;
  position: relative;
}

.assignedperson-one {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: inline-block;
  top: 0px;
  position: relative;
  z-index: 2;
  background: url("../image/person-1.jpg");
  border: 0px solid #fff;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-size: cover;
}

.assignedperson-rest {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: inline-block;
  top: 0px;
  left: -20px;
  position: relative;
  z-index: 1;
  background: url("../image/person-2.png");
  border: 2px solid #fff;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-size: cover;
}

.task-more {
  flex: 3%;
  max-width: 3%;
}

.connected-apps-container {
  width: 100%;
  height: 150px;
  margin-top: 14px;
}

.apps-container {
  margin-top: 12px;
  height: 100%;
  width: 100%;
}

.app-box {
  margin-right: 10px;
  display: inline-block;
  width: 140px;
  height: 118px;
  background: #352641;
  border: none;
  border-radius: 30px;
}

.app-image-div {
  width: 50%;
  height: 50px;
  margin-top: 14px;
  margin-left: 22px;
  position: relative;
}

.app-img {
  position: absolute;
  width: 45px;
}

.app-name-div {
  width: 50%;
  height: 30px;
  margin-left: 22px;
  position: relative;
  font-size: 12px;
  color: #ffffff;
  line-height: 1.1;
}

.app-name {
  position: absolute;
}

.message svg .a {
  fill: #fff;
}

.next-button-container {
  width: 100%;
  height: 170px;
  padding: 15px;
}

.btn-next {
  margin-top: 43px;
  width: 330px;
  color: #fff;
  height: 46px;
  background: #d4d6dc;
  border-radius: 30px;
  border: 0;
  box-shadow: 0 5px 10px #ff696940;
  color: #515c6f;
  font-size: 14px;
  position: relative;
  font-family: "IBM Plex Sans";
  font-weight: 600;
}

.btn-next .right-icon-container {
  background: #fff;
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.btn-next .right-icon-container img {
  margin-top: 3px;
}

.compare-competitors-wrapper {
  width: 100%;
  height: 300px;
  padding: 15px;
  font-size: 14px;
  font-weight: 800;
}

.compare-competitors-container {
  display: inline-block;
  margin-top: 43px;
  width: 345px;
  color: #fff;
  height: 60px;
  background: #fff;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 5px 10px #e7eaf0;
  color: #515c6f;
  font-size: 14px;
  position: relative;
}

.compare-competitors-container .left-icon-container {
  display: inline-block;
  width: 30px;
  height: 20px;
  text-align: center;
  margin-top: 19px;
  margin-left: 10px;
}

.compare-competitors-container .left-icon-container img {
  width: 20px;
}

.compare-competitors-container .right-icon-container {
  display: inline-block;
  height: 100%;
  width: calc(100% - 70px);
  position: absolute;
}

.web-address-label {
  display: block;
  height: 30%;
  width: 100%;
  font-size: 12px;
  color: #515c6f;
  text-transform: uppercase;
  margin-left: 1%;
  opacity: 0.5;
  font-weight: 500;
  margin-top: 8px;
}

.web-address-input {
  border: none;
  width: 100%;
  font-size: 15px;
  left: 1%;
  margin-left: 1%;
}

.compare-button-container {
  display: inline-block;
  margin-left: 8px;
  position: relative;
  bottom: 0;
}

.btn-compare {
  margin-top: 43px;
  width: 330px;
  color: #fff;
  height: 46px;
  background: #ff6969;
  border-radius: 30px;
  border: 0;
  box-shadow: 0 5px 10px #ff696964;
  color: #fff;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  text-transform: uppercase;
}

.btn-compare .right-icon-container {
  background: #fff;
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.btn-compare .right-icon-container img {
  margin-top: 3px;
}

.compared-competitor-wrapper {
  display: inline-block;
  margin-top: 43px;
  width: 350px;
  height: 36px;
  background: #d4d6dc;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 5px 10px #00000016;
  color: #515c6f;
  font-size: 14px;
  position: relative;
}

.compared-competitor-wrapper .left-icon-container {
  display: inline-block;
  width: 30px;
  height: 22px;
  text-align: center;
  margin-top: 5px;
  margin-left: 10px;
}

.compared-competitor-wrapper .web-address-entered-container {
  display: inline-block;
  background: #d4d6dc;
  height: 36px;
  width: calc(100% - 75px);
  position: absolute;
}

.web-address-entered {
  width: 100%;
  font-size: 13px;
  line-height: 34px;
  text-transform: uppercase;
  color: #515c6f;
}

.compared-competitor-wrapper .right-icon-container {
  /* display: inline-block; */
  width: 30px;
  height: 22px;
  text-align: center;
  margin-top: 5px;
  float: right;
  margin-right: 10px;
}

.compared-competitor-wrapper .right-icon-container img {
  width: 20px;
}

/* Pre-Footer and Footer Items CSS - Written on Aug 18, 2019 by Ayush */
.pre-footer-container {
  width: 100%;
  height: 120px;
  margin: 0;
  padding: 38px 70px;
}
.pre-foot-mai-logo {
  display: inline-block;
  width: 7%;
  height: 40px;
  background: #fff;
  margin: 0;
  line-height: 40px;
  font-size: 32px;
  font-weight: 900;
  padding: 0;
}
.pre-foot-mai-links {
  display: inline-block;
  width: 55%;
  height: 40px;
  line-height: 40px;
  margin: 0 75px;
  padding: 0;
  text-align: right;
}
.pre-foot-item {
  color: #666666;
  font-size: 16px;
  line-height: 40px;
  display: inline;
  margin: 0px 5px;
}
.pre-footer-links {
  color: #666666;
}
.pre-foot-mai-copyright {
  display: inline-block;
  width: 20%;
  height: 40px;
  margin: 0;
  line-height: 40px;
  margin: 0px 5px;
  padding: 0;
  text-align: right;
}
.pre-foot-copy {
  color: #6e6e6e;
  font-size: 10px;
  line-height: 40px;
  margin: 0px 5px;
}

/* Footer container  */
.footer-container {
  width: 100%;
  height: 367px;
  background: #f8f9fb;
  margin: 0;
  padding: 80px;
}
.footer-seo-items-container {
  width: 15%;
  height: 200px;
}
.footer-items-heading {
  width: 100%;
  height: 20px;
  font-size: 18px;
  font-family: "IBM Plex Sans";
  color: #666666;
  font-weight: bold;
}
.footer-items {
  margin-top: 20px;
}
.footer-item {
  width: 100%;
  height: 20px;
  margin: 10px 0;
}
.footer-item a {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 16px;
  color: #666666;
}
.footer-local-items-container {
  width: 18%;
  height: 200px;
  margin-left: 55px;
}

.footer-marketing-items-container {
  width: 21%;
  height: 200px;
  margin-left: 55px;
}

.footer-separation {
  width: 0px;
  height: 200px;
  border: 1px solid #70707052;
  border-radius: 4px;
  margin-left: 45px;
}
.footer-subscribe-items-container {
  width: 25%;
  height: 200px;
  margin-left: 40px;
  margin-top: 10px;
}
.subscribe-blog-container {
  width: 100%;
  height: 20px;
  font-size: 16px;
  font-family: "IBM Plex Sans";
  color: #666666;
  font-weight: 300;
}
.subscribe-body-container {
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  color: #666666;
  font-weight: 200;
}
.subscribe-btn {
  margin-top: 43px;
  width: 240px;
  color: #fff;
  height: 46px;
  background: #ff6969;
  border-radius: 30px;
  border: 0;
  box-shadow: 0 5px 10px #ff696964;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}
.mob-view {
  display: none;
}
.desktop-view {
  display: block;
}

@media screen and (max-width: 1250px) {
  .desktop-view {
    display: none;
  }
  .mob-view {
    display: block;
    padding: 7px;
  }
  .mob-all-tasks {
    height: 56px;
    /* background: red; */
    /* padding: 0 20px; */
    width: 100%;
    padding-left: 9px;
  }

  .heading-container {
    float: left;
    font-size: 26px;
    line-height: 56px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    vertical-align: middle;
  }
  .mob-page-heading {
    display: inline-block;
    font-family: Bebas Neue, Bold;
  }
  .hamburger-container {
    float: right;
    margin: 20px 0;
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    vertical-align: middle;
  }
  .mob-all-tasks-tag {
    display: inline-block;
    height: 30px;
    width: 86px;
    background: -webkit-linear-gradient(right, #3905cd, #9014ee) !important;
    text-align: center;
    line-height: 30px;
    border: none;
    border-radius: 4px;
    color: #fff;
    box-shadow: 4px 4px 12px 2px #3905cd45;
    font-weight: 700;
    font-size: 12px;
  }
  .mob-seo-tasks {
    display: inline-block;
    height: 30px;
    width: 86px;
    background: #fff;
    text-align: center;
    line-height: 30px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    color: #0d0e10;
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 5px;
    font-weight: 700;
  }
  .task-head {
    height: 35px;
    width: calc(100%);
    font-size: 12px;
    font-family: IBM Plex Sans, regular;
    font-weight: 800;
  }
  .main-tasks-container {
    width: calc(100%);
  }
  .tasks-container {
    width: 100%;
    height: 210px;
  }
  .tasks-white-box {
    width: 100%;
    height: 56px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 12px #d3d3d3;
    margin-top: 0px;
    display: flex;
    align-items: center;
  }
  .right-sign {
    flex: 10%;
    max-width: 10%;
    margin-left: 3%;
  }
  .white-box-text {
    flex: 55%;
    max-width: 55%;
    font-size: 13px;
    font-weight: 600;
    font-family: IBM Plex Sans, SemiBold;
    color: #0d0e10;
  }
  .white-box-redTag {
    flex: 22%;
    max-width: 22%;
    font-size: 11px;
    font-family: IBM Plex Sans, SemiBold;
    text-align: center;
  }
  .white-box-arrow {
    flex: 10%;
    max-width: 10%;
  }
  .connected-apps-container {
    width: 100%;
    height: 175px;
  }
  .next-button-container {
    width: 100%;
    height: 225px;
  }
  .wesite-container {
    margin: auto;
    margin-top: 20px;
    width: 333px;
    color: #fff;
    height: 60px;
    background: #fff;
    border-radius: 10px;
    border: 0;
    box-shadow: 0 5px 10px #e7eaf0;
    color: #515c6f;
    font-size: 14px;
    position: relative;
  }
  .left-icon-container {
    display: inline-block;
    width: 30px;
    height: 25px;
    text-align: center;
    margin-top: 19px;
    margin-left: 10px;
  }
  .right-icon-container {
    display: inline-block;
    height: 100%;
    width: calc(100% - 70px);
    position: absolute;
  }
  .web-address-label {
    display: block;
    height: 30%;
    width: 100%;
    font-size: 12px;
    color: #515c6f;
    text-transform: uppercase;
    margin-left: 1%;
    opacity: 0.5;
    font-weight: 500;
    margin-top: 8px;
  }
  .web-address-input {
    border: none;
    width: 100%;
    font-size: 15px;
    left: 1%;
    margin-left: 1%;
  }
  .mob-filters-container {
    height: 48px;
    padding: 5px 1px;
    overflow: hidden;
    width: 100%;
  }
  .apps-container {
    margin-top: 12px;
    height: 100%;
    width: 100%;
  }
  .app-box {
    display: inline-block;
    width: 140px;
    height: 118px;
    background: #352641;
    border: none;
    border-radius: 30px;
    margin-right: 10px;
  }
  .app-black-base {
    width: 50%;
    height: 50px;
    margin-top: 14px;
    margin-left: 22px;
    position: relative;
  }
  .app-black-base img {
    position: absolute;
  }
  .app-black-base-text {
    width: 50%;
    height: 30px;
    margin-left: 22px;
    position: relative;
    font-size: 12px;
    color: #ffffff;
    line-height: 1.1;
  }
  .app-black-base-text span {
    position: absolute;
  }
  .add-competitor-button {
    margin-left: 22px;
    margin-top: 12px;
    width: 330px;
    color: #fff;
    height: 50px;
    background: #ff6969;
    border-radius: 30px;
    border: 0;
    box-shadow: 0 5px 10px #ff696964;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    text-transform: uppercase;
    line-height: 50px;

    position: relative;
  }
  .appButton-right-icon {
    background: #fff;
    width: 28px;
    height: 28px;
    border: none;
    border-radius: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .appButton-right-icon img {
    width: 7px;
    margin-bottom: 23px;
  }
  .your-competitor-container {
    display: inline-block;
    margin-top: 12px;
    width: 350px;
    height: 36px;
    background: #d4d6dc;
    border-radius: 10px;
    border: 0;
    box-shadow: 0 5px 10px #00000016;
    color: #515c6f;
    font-size: 14px;
    position: relative;
    margin-left: 12px;
  }
  .your-comp-left-icon {
    display: inline-block;
    width: 30px;
    height: 22px;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
  }
  .web-address-entered-container {
    display: inline-block;
    background: #d4d6dc;
    height: 36px;
    width: calc(100% - 75px);
  }
  .web-address-entered {
    width: 100%;
    font-size: 13px;
    line-height: 34px;
    text-transform: uppercase;
    color: #515c6f;
  }
  .your-comp-right-icon {
    display: inline-block;
    width: 30px;
    height: 22px;
    text-align: center;
    margin-top: 5px;
    margin-right: 10px;
    position: absolute;
  }
  .add-button {
    width: 56px;
    height: 56px;
    background: #352641;
    border-radius: 40px;
    border: 0;
    bottom: 40px;
    margin-left: 24px;
    position: fixed;
    right: 20px;
  }
  .sub-tags {
    font-size: 13px;
    color: #b5b5b6;
  }
  .small1 {
    font-size: 8px;
    font-family: IBM Plex Sans, Regular;
    color: #ff6969;
  }
  .small2 {
    font-size: 8px;
    font-family: IBM Plex Sans, Regular;
    color: #0d0e10;
  }
  .mob-filters-inner {
    width: 100%;
    overflow-x: auto;
    display: flex;
    overflow-y: hidden;
  }
}
.smart-codes-dropdown{
  padding-left: 5px;
}