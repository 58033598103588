body {
    font-family:IBM Plex Sans;
 }   
.login-wrap {
    width:100%;
    height:auto;
    margin: 0 auto;
}
/*............login/header.............  */
.login-header {
    width:100%;
    height:70px;
    box-shadow: 0 1px 0 #E4E4E4; 
    overflow: hidden;
}
.login-logo {
    width: 10%;
    height: 70px;
    padding: 9px;
    margin: 1%;
}
.login-mai-logo {
    font-family: "IBM Plex Sans";
    height: 20px;
    font-size: 16px;
    font-weight: 600;
    margin: 0px 15px;
}
/*............login/main heading.............  */
.login-main-back {
    width:100%;
    height:auto;
    margin-top: 7%;
}
.mai-heaing {
    width:20%;
    height:92px;
    margin: 0 auto;
    font-size: 75px;
    color:#515C6F;
    text-align: center;
    font-family: IBM Plex Sans, Bold;
    line-height: 92px;
    font-weight: 700;
}
/*............login/forgott.............  */
.login-forgot {
    width:500px;
    height:50px;
    margin:11px auto;
}
.signin-link {
    width:25%;
    display: inline-block;
    text-align: center;
}
.signin-link a {
    font-size: 30px;
    color: #515C6F;
    font-family: "IBM Plex Sans", Bold;
    font-weight: 700; 
}
.signin-link a:hover {
    text-decoration: none;
    cursor: pointer;
}
.login-link {
    width:25%;
    display: inline-block;
    text-align: center;
}
.login-link a {
    font-size: 30px;
    color: #E7EAF0;
    font-family: "IBM Plex Sans", Bold;
    font-weight: 700; 
}
.login-link a:hover {
    text-decoration: none;
    cursor: pointer;
}
.forgot-link{
    width:48%;
    display: inline-block;
}
.forgot-link a {
    font-size: 30px;
    color: #E7EAF0;
    font-family: "IBM Plex Sans", Bold;
    font-weight: 600;
}
.forgot-link a:hover {
    text-decoration: none;
} 
/*............login/input.............  */
.login-input {
    width:380px;
    height:auto;
    margin:0 auto;
    margin-top: 35px;
}
.login-competitors-container {
    width: 335px;
    color: #fff;
    height: 60px;
    background: #fff;
    border-radius: 10px 10px 0 0;
    border: 0;
    box-shadow: 0 5px 10px #e7eaf0;
    color: #515c6f;
    font-size: 14px;
    position: relative;
    margin: 0 auto;
}
.user-border {
    border-radius: 0;
}
.pass-border {
    border-radius: 0 0 10px 10px !important;
}
.login-competitors-container .login-left-icon {
    display: inline-block;
    width: 50px;
    height: 20px;
    text-align: center;
    margin-top: 15px;
}
.login-competitors-container .login-left-icon img {
    width: 20px;
}
.login-right-icon {
    display: inline-block;
    height: 100%;
    width: calc(100% - 70px);
    position: absolute;
}
.web-address-input-container input::placeholder {
    font-weight: 400;
}
/*............login/login-button.............  */ 
.login-button {
    width:330px;
    height:55px;
    margin:15px auto;
}
.login-btn-compare {
    width: 330px;
    color: #fff;
    height: 46px;
    background: #ff6969;
    border-radius: 30px;
    border: 0;
    box-shadow: 0 5px 10px #ff696964;
    color: #fff;
    font-size: 14px;
    position: absolute;
    text-transform: uppercase;
    font-weight:600;
}
.login-btn-compare .right-icon-container {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgb(255, 255, 255);
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 30px;
}
.right-icon-container img {
    vertical-align: middle;
    border-style: none;
    padding-top: 4px;
}
.login-below-text {
    width:30%;
    margin:0 auto;
    text-align: center;
    font-size: 13px;
    color:lightgray;
    font-weight: 600;
}
/*............login/google-button.............  */
.social-button-back {
    width:330px;
    height:50px;
    margin:15px auto;
    color: #fff;
}
.social-button-back .li-button {
    text-transform: none;
    }
.social-button-back .spinner {
    color: #fff;
}

.google-button {
    width: 330px;
    color: #fff;
    height: 46px;
    background: #4285F4;
    border-radius: 30px;
    border: 0;
    box-shadow: 0 5px 10px #4285F4;
    color: #fff;
    font-size: 14px;
    position: absolute;
    text-transform: uppercase;
    font-weight:600;
}
/*............login/facebook-button.............  */
.li-button {
    width: 330px;
    color: #fff;
    height: 46px;
    background:#3A5997;
    border-radius: 30px;
    border: 0;
    box-shadow: 0 5px 10px #3A5997;
    color: #fff;
    font-size: 14px;
    position: absolute;
    text-transform: uppercase;
    font-weight:600;
}
/*............login/swipe-option.............  */
.swipe-back {
    width: 30%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10%;
}
.swipe {
    font-size: 12px;
    color:#515C6F;
    text-align: center
}
.swipe-back a {
    color:#FF6969;
    font-size: 12px;
}
.swipe-back a:hover {
    text-decoration: none;
}

/*............Mobile-view - login/header.............  */
@media screen and (max-width: 1250px) {
    .login-wrap {
        width:100%;
        height:auto;
        margin: 0 auto;
        border-bottom: none;
        background-color:#F5F6F8;
        overflow: hidden; 
    }
    .login-main-back {
        width: 410px; 
    }
    .signin-link {
        width:25%;
        display: inline-block;
        text-align: center;
    }
    .signin-link a {
        font-size: 23px;

    }
    .login-link a {
        font-size: 23px;

    }
    .forgot-link a {
        font-size: 23px;

    }
    .login-header {
        display: none;
    }
    .mai-heaing {
        display: none;
    }
    .login-forgot {
        width: 92%; 
        margin-bottom: 10%;
    }
    .pre-footer-container {
        display: none;
    }
    .footer-container {
        display: none;
    }
    .login-input {
        width: 88%; 
        margin: 10px auto;
    }
    .below-text {
        width: 60%;
        margin: 6px auto;
        text-align: center;
    }
    .login-below-text {
        margin-top: 17.5%;
    }
    .swipe-back {
        width: 56%;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 0%;
        margin-top: 19.2%;
    }  
}

.Invalid{
    width:auto;
    font-family: "IBM Plex Sans", Bold;
    text-align: center;
    color:#FF6969 ;
}