.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.taskDetailWrapper {
  width: 100%;
  height: 2000px;
  background-color: antiquewhite;
}
.task-detail-name {
  flex: 38%;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  font-family: "IBM Plex Sans";
  color: #0d0e10;
}

.task-detail-name a {
  text-decoration: none;
  color: #9d9d9d;
}
.task-detail-name a span {
  color: #000000;
}
.detail-task-count {
  flex: 15%;
  max-width: 15%;
  font-size: 16px;
  font-family: "IBM Plex Sans";
  color: #515c6f;
}
.task-detail-significance {
  flex: 10%;
  max-width: 7%;
  /* background: #ff6969; */
  height: 35px;
}
.header-know-logo {
  font-family: "IBM Plex Sans";
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  margin: 0px 14px;
}
.know-logo {
  width: 15%;
  height: 38px;
  padding: 9px;
  margin: 0;
  border-right: 1px solid #00000010;
}

.plain-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/*......Main Wraopper......*/

.mainWrapper {
  width: 80%;
  height: auto;
  margin: auto;
  margin-top: 56px;
}

.mob-mainWrapper {
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 30px;
}

/*............Section-1/Assign..........*/
.headingBack {
  width: 100%;
  height: auto;
  display: flex;
}
.breadcrumbs-wrapper {
  width: 200px;
  height: 20px;
}
.breadcrumb {
  padding: 0;
  margin-bottom: 0;
}
.breadcrumb-item {
  font-size: 14px;
  color: #515c6f;
  font-family: "IBM Plex Sans";
}
.breadcrumb-item a {
  text-decoration: none;
  color: #515c6f;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: ">";
}
.text {
  flex: 40%;
  max-width: 40%;
  margin-right: 6%;
  font-size: 30px;
  color: #515c6f;
  font-family: "IBM Plex Sans";
}
/*............confirm Modal.........*/
.confirm-modal-dialog {
  margin: 0;
  float: center;
}
.confirm-modal {
  max-width: 390px;
  margin: 18% auto;
}
.confirmModalbody {
  padding: 1.8rem 1rem;
  font-size: 18px;
  font-family: "IBM Plex Sans";
  font-weight: 300;
}
.no-btn {
  width: 75px;
  height: 35px;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  color: #3e3f42;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  font-weight: 500;
}
.yes-btn {
  width: 75px;
  height: 35px;
  border: 1px solid #d8dce6;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  font-weight: 500;
  background-color: #ff6969;
}
.yes-btn:hover {
  color: #fff;
}
.mark-task {
  flex: 12%;
  max-width: 12%;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  color: #ff6969;
  margin-right: 6%;
  margin-top: 10px;
}
.decline-task {
  flex: 12%;
  max-width: 12%;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  color: #515c6f;
  margin-right: 6%;
  margin-top: 10px;
}
.decline-task-button {
  font-family: "IBM Plex Sans";
  color: #515c6f;
  padding: 0;
  border: none;
  background: none;
}
.mark-task img {
  width: 18px;
}
.decline-task img {
  width: 12px;
}
.assign {
  flex: 30%;
  max-width: 160px;
  background-color: #ff6969;
  color: #fff;
  height: 46px;
  border-radius: 23px;
  border: 0;
  box-shadow: 0 1px 2px #ff6969;
  font-size: 20px;
  /* margin-left: 15%; */
  position: relative;
  font-family: "IBM Plex Sans";
}
.details-assigned-container {
  flex: 8%;
  max-width: 8%;
  position: absolute;
  right: 2.9%;
}
.details-assignPerson-one {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: inline-block;
  top: 0px;
  position: relative;
  z-index: 2;
  background: url(../image/person-1.jpg);
  border: 0px solid #fff;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-size: cover;
}
.details-assignPerson-rest {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: inline-block;
  top: 0px;
  left: -20px;
  position: relative;
  z-index: 1;
  background: url(../image/person-2.png);
  border: 2px solid #fff;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-size: cover;
}

/*............Section-2/Rating..........*/
.ratingBack-n {
  width: 100%;
  height: auto;
  /* display: flex; */
  margin-top: 11px;
}
.ratingMap-back {
  flex: 20%;
  max-width: 190px;
  margin-right: 9%;
}
.ratingMap-head {
  width: 100%;
  height: 25px;
}
.ratingMap-text {
  width: 58%;
  height: 25px;
  color: #ff6969;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  display: inline-block;
}
.ratingMap-tag {
  width: 72px;
  height: 23px;
  border-radius: 14px;
  font-size: 13px;
  color: #fff;
  font-family: "IBM Plex Sans";
  text-align: center;
  line-height: 25px;
  display: inline-block;
}
.Low {
  background: #a9a9a9;
}
.Medium {
  background: #ff8c00;
}
.High {
  background: #ff6969;
}
.ratingMap-graph {
  width: 93%;
  height: 83px;
  margin-top: 15px;
}

.ratingPagesCount-n {
  flex: 30%;
  max-width: 30%;
  /* background-color:darkgreen; */
  color: #515c6f;
  font-size: 38px;
  margin: auto 9% auto 0;
  line-height: 1;
}

.ratingTraffic {
  /* background-color:darkgoldenrod; */
  color: #515c6f;
  font-size: 38px;
  margin: auto;
}

/*............Section-3/content in column..........*/
.contentBack {
  width: 100%;
  height: auto;
  margin-top: 33px;
}
.content {
  font-size: 16px;
  font-family: "IBM Plex Sans";
}
.cus-padding {
  padding-left: 0;
}

/*............Section-4/Testimonial..........*/
.testimonialBack {
  width: 93%;
  height: 122px;
  display: flex;
  margin: 0 auto;
  margin-top: 27px;
}
.testmonialImage {
  flex: 30%;
  max-width: 87px;
  height: 87px;
  border-radius: 50%;
  margin-right: 21px;
}
.testmonialImage img {
  width: 87px;
  border-radius: 50%;
  /* padding-left: 2px; */
  border: 5px solid #ff6969;
}
.testmonialMsg {
  flex: 80%;
  max-width: 80%;
}
.msg-content {
  font-size: 20px;
  font-family: "IBM Plex Sans";
  color: #0d0e10;
  height: auto;
  line-height: 1.2;
}
.msg-profile {
  height: 38px;
  margin-top: 15px;
}
.profile-name {
  font-size: 14px;
  color: #0d0e10;
  font-family: "IBM Plex Sans";
  font-weight: 700;
}
.profile-designation {
  font-size: 12px;
  color: #0d0e10;
  font-family: "IBM Plex Sans";
}
/*............Section-5/Affectd Page..........*/
.affectedPage {
  width: 100%;
  margin-top: 30px;
}
.affectedPage-head {
  width: 100%;
  height: 25px;
  font-size: 16px;
  color: #515c6f;
  font-family: "IBM Plex Sans";
  font-weight: 700;
}
.affectedPage-head img {
  width: 17px;
  margin-left: 19px;
}
.affectedPage-bar {
  margin-top: 18px;
  /* overflow-y: auto;
    height: 315px; */
}
.task-details-container {
  width: 100%;
  height: 70px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 4px #d3d3d3;
  margin-top: 8px;
  display: flex;
  align-items: center;
}
.see-all-task {
  width: 100%;
  height: 20px;
  background: #515c6f;
  border-radius: 0 0 10px 10px;
  color: white;
  font-size: 12px;
  font-family: "IBM Plex Sans";
  text-align: center;
  line-height: 20px;
}
/*............Section-5/Affectd Page..........*/
.saySomthing {
  width: 92%;
  height: 35px;
  margin-top: 28px;
  margin-left: 8%;
  border-bottom: 2px solid #515c6f;
}
.searchTasks {
  width: 100%;
  height: 35px;
  margin-top: 28px;
  border-bottom: 2px solid #515c6f;
}
.write-comments {
  width: 93%;
  border: none;
  color: #515c6f;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  font-weight: 700;
  margin-right: 3%;
}
.write-comments::placeholder {
  color: lightgray;
}
.saySomthing img {
  width: 28px;
}
/*............Section-5/Affectd Page..........*/
.comments-wrapper {
  width: 100%;
  height: auto;
  margin-top: 12px;
  margin-bottom: 80px;
}
.comments-back {
  width: 92%;
  height: 170px;
  margin-left: 8%;
}
.user-profile {
  width: 100%;
  height: 50px;
  margin-top: 29px;
  display: flex;
}
.user-image {
  flex: 30%;
  max-width: 36px;
  border-radius: 50%;
  height: 36px;
}
.user-image img {
  width: 36px;
  border-radius: 50%;
}
.user-id {
  flex: 35%;
  max-width: 35%;
  margin-left: 20px;
  height: 40px;
}
.user-name {
  width: 100%;
  height: 19px;
  font-size: 16px;
  color: #241332;
  font-family: "IBM Plex Sans";
  font-weight: 700;
}
.date {
  width: 100%;
  height: 15px;
  font-size: 12px;
  color: #352641;
  font-family: "IBM Plex Sans";
}
.down-arrow {
  flex: 30%;
  max-width: 30%;
  background-color: #241332;
}
.comment-like {
  width: 100%;
  height: auto;
  margin-top: 20px;
}
.user-comment {
  color: #515c6f;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  height: auto;
}
.like-icon {
  height: 25px;
}
.comment-count {
  width: 50px;
  height: 16px;
  margin-right: 23px;
  display: inline-block;
  font-size: 12px;
  color: #817889;
  font-weight: 700;
}
.comment-count img {
  width: 16px;
}
.heart-count {
  width: 50px;
  height: 16px;
  display: inline-block;
  font-size: 12px;
  color: #817889;
  font-weight: 700;
}
.heart-count img {
  width: 20px;
}

/* Pre-Footer and Footer Items CSS - Written on Aug 18, 2019 by Ayush */
.pre-footer-container {
  width: 100%;
  height: 120px;
  margin: 0;
  padding: 38px 70px;
}
.pre-foot-mai-logo {
  display: inline-block;
  width: 7%;
  height: 40px;
  background: #fff;
  margin: 0;
  line-height: 40px;
  font-size: 32px;
  font-weight: 900;
  padding: 0;
}
.pre-foot-mai-links {
  display: inline-block;
  width: 55%;
  height: 40px;
  line-height: 40px;
  margin: 0 75px;
  padding: 0;
  text-align: right;
}
.pre-foot-item {
  color: #666666;
  font-size: 16px;
  line-height: 40px;
  display: inline;
  margin: 0px 5px;
}
.pre-footer-links {
  color: #666666;
}
.pre-foot-mai-copyright {
  display: inline-block;
  width: 20%;
  height: 40px;
  margin: 0;
  line-height: 40px;
  margin: 0px 5px;
  padding: 0;
  text-align: right;
}
.pre-foot-copy {
  color: #6e6e6e;
  font-size: 10px;
  line-height: 40px;
  margin: 0px 5px;
}

/* Footer container  */
.footer-container {
  width: 100%;
  height: 367px;
  background: #f8f9fb;
  margin: 0;
  padding: 80px;
}
.footer-seo-items-container {
  width: 15%;
  height: 200px;
}
.footer-items-heading {
  width: 100%;
  height: 20px;
  font-size: 18px;
  font-family: "IBM Plex Sans";
  color: #666666;
  font-weight: bold;
}
.footer-items {
  margin-top: 20px;
}
.footer-item {
  width: 100%;
  height: 20px;
  margin: 10px 0;
}
.footer-item a {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 16px;
  color: #666666;
}
.footer-local-items-container {
  width: 18%;
  height: 200px;
  margin-left: 55px;
}

.footer-marketing-items-container {
  width: 21%;
  height: 200px;
  margin-left: 55px;
}

.footer-separation {
  width: 0px;
  height: 200px;
  border: 1px solid #70707052;
  border-radius: 4px;
  margin-left: 45px;
}
.footer-subscribe-items-container {
  width: 25%;
  height: 200px;
  margin-left: 40px;
  margin-top: 10px;
}
.subscribe-blog-container {
  width: 100%;
  height: 20px;
  font-size: 16px;
  font-family: "IBM Plex Sans";
  color: #666666;
  font-weight: 300;
}
.subscribe-body-container {
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  color: #666666;
  font-weight: 200;
}
.subscribe-btn {
  margin-top: 43px;
  width: 240px;
  color: #fff;
  height: 46px;
  background: #ff6969;
  border-radius: 30px;
  border: 0;
  box-shadow: 0 5px 10px #ff696964;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}
.mob-view {
  display: none;
}
.desktop-view {
  display: block;
}

/*............Mobile View..........*/
@media screen and (max-width: 1250px) {
  .desktop-view {
    display: none;
  }
  /* .mob-view {
        padding:0 !important;
    } */
  .detail-mob-inner {
    width: 100%;
    height: 730px;
    background-color: azure;
  }

  .mob-detail-all-tasks {
    height: 56px;
    width: 100%;
  }
  /*............Mobile View/header..........*/
  .top-left-heading {
    color: #ff6969;
    font-size: 14px;
    font-family: "IBM Plex Sans";
    float: right;
    margin: 20px 0;
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    vertical-align: middle;
  }
  .detail-mob-main {
    width: 95%;
    height: auto;
    margin-top: 24px;
    margin: 0 auto;
  }
  /*............Mobile View/top..........*/
  .heading-wrapper {
    width: 100%;
    height: 300px;
    border-bottom: 1px solid #d1d1d1;
  }
  .detail-mob-heading {
    width: 85%;
    height: 62px;
    font-size: 26px;
    color: #515c6f;
    font-family: "IBM Plex Sans";
    font-weight: 700;
    line-height: 1;
  }
  .ratingMap-text {
    width: 100%;
    height: 18px;
    color: #ff6969;
    font-size: 14px;
    font-family: "IBM Plex Sans";
    margin-bottom: 5px;
  }

  .ratingMap-tag {
    width: 72px;
    height: 25px;
    border-radius: 14px;
    font-size: 13px;
    margin-top: 5px;
    color: #fff;
    font-family: "IBM Plex Sans";
    text-align: center;
    line-height: 25px;
    margin-bottom: 19px;
  }
  .Low {
    background: #a9a9a9;
  }
  .Medium {
    background: #ff8c00;
  }
  .High {
    background: #ff6969;
  }
  /*............Mobile View/description..........*/
  .description-container {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #d1d1d1;
  }
  .mob-block-head {
    height: 18px;
    margin-top: 22px;
    font-size: 14px;
    font-weight: 700;
    font-family: "IBM Plex Sans";
    color: #0d0e10;
  }
  .description-text {
    width: 95%;
    height: auto;
    font-size: 12px;
    color: #515c6f;
    font-family: "IBM Plex Sans";
    margin-left: 5px;
    margin-top: 12px;
  }
  .decription-profile {
    width: 98%;
    height: 54px;
    margin-top: 19px;
  }
  .detail-mob-white-box {
    width: 95%;
    height: 54px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 12px #d3d3d3;
    margin-top: 8px;
    margin-left: 11px;
    display: flex;
    align-items: center;
    display: flex;
  }
  .decription-profile-image {
    flex: 13%;
    max-width: 13%;
    height: 100%;
    margin-left: 16px;
  }
  .decription-profile-image img {
    width: 36px;
    border-radius: 50%;
    margin: 10px auto;
  }
  .decription-profile-info {
    flex: 65%;
    max-width: 65%;
    height: 100%;
    margin-left: 15px;
  }
  .decription-profile-name {
    height: 19px;
    font-size: 15px;
    font-family: "IBM Plex Sans";
    font-weight: 700;
    color: #241332;
    margin-top: 11px;
  }
  .decription-profile-status {
    height: 18px;
    font-size: 12px;
    font-family: "IBM Plex Sans";
    color: #241332;
  }
  .decription-arrow {
    flex: 10%;
    max-width: 10%;
    height: 29px;
    margin-left: 8px;
    line-height: 22px;
  }
  .decription-arrow img {
    width: 7px;
    padding: 0 auto;
    margin-left: 8px;
  }
  .decription-task-status {
    width: 95%;
    height: 35px;
    margin-top: 30px;
    margin-bottom: 50px;
    padding-top: 7px;
    text-align: center;
    color: #515c6f;
    font-size: 14px;
    font-family: "IBM Plex Sans";
  }

  /*............Mobile View/affected..........*/
  .mob-affected-container {
    width: 100%;
    height: auto;
    /* background-color: aqua; */
    border-bottom: 1px solid #d1d1d1;
  }
  .card {
    border: none;
    width: 100%;
    margin-top: 20px;
  }
  .card-header {
    padding: 0;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: none;
    display: flex;
  }

  .affected-expert-mob-head {
    flex: 85%;
    max-width: 85%;
    height: 20px;
    font-size: 14px;
    font-family: "IBM Plex Sans";
    color: #0d0e10;
    font-weight: 700;
  }
  .card-header .decription-arrow img {
    width: 13px;
    cursor: pointer;
  }
  .affected-card {
    width: 100%;
    height: auto;
    margin-top: 17px;
    margin-bottom: 30px;
  }
  .mob-affected-white-box {
    width: 94%;
    height: 70px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 12px #d3d3d3;
    margin-bottom: 8px;
    margin-left: 11px;
    align-items: center;
  }
  .mob-affected-box-text {
    width: 100%;
    height: 64px;
    line-height: 53px;
    font-size: 16px;
    font-family: "IBM Plex Sans";
    color: #9d9d9d;
    padding-left: 7%;
    text-align: left;
  }
  .mob-affected-box-text span {
    color: #0d0e10;
    font-weight: 600;
  }

  /*............Mobile View/expert..........*/

  .mob-expert-container {
    width: 100%;
    height: auto;
  }
  /* .mob-expert-card {
        width: 95%;
        height: auto;
        margin-top:24px;
        margin-bottom:30px;
    }*/
  .mob-expert-white-box {
    width: 94%;
    height: auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 12px #d3d3d3;
    margin-bottom: 40px;
    margin-left: 11px;
    align-items: center;
  }
  .mob-expert-box-text {
    width: 100%;
    height: 70px;
    line-height: 53px;
    font-size: 16px;
    font-family: "IBM Plex Sans";
    color: #9d9d9d;
    padding-left: 7%;
    text-align: left;
  }
  .mob-expert-say-profile {
    width: 100%;
    height: 45px;
    border-radius: 8px;
    margin-top: 9px;
    display: flex;
    align-items: center;
    display: flex;
  }
  .expert-say-profile-image {
    flex: 13%;
    max-width: 40px;
    height: 100%;
    margin-left: 21px;
    margin-top: 9px;
    height: 40px;
    border-radius: 50%;
    background: #f6a93b;
  }
  .expert-profile-info {
    flex: 65%;
    max-width: 75%;
    height: 100%;
    margin-left: 15px;
  }
  .mob-expert-say-text {
    width: 90%;
    height: auto;
    /* line-height: 53px; */
    font-size: 15px;
    font-family: "IBM Plex Sans";
    color: #0d0e10;
    padding-left: 21px;
    margin-top: 19px;
    padding-bottom: 17px;
  }
  /*............Mobile View/comment..........*/
  .comment-container {
    width: 100%;
    height: 100px;
  }
  .mob-saySomthing {
    width: 80%;
    height: 85px;
    margin-top: 29px;
    margin-left: 11%;
    border-bottom: 1px solid #515c6f;
  }
  .mob-write-comments {
    width: 87%;
    border: none;
    color: #515c6f;
    font-size: 16px;
    font-family: "IBM Plex Sans";
    font-style: italic;
    font-weight: 500;
    margin-right: 3%;
    margin-top: 20px;
    height: 55px;
    padding-left: 6%;
  }
  .mob-saySomthing img {
    width: 24px;
    margin-top: 23px;
  }
  .mob-comments-wrapper {
    width: 100%;
    height: auto;
    margin-top: 70px;
    margin-bottom: 40px;
  }
  .mob-comments-back {
    width: 87%;
    height: auto;
    margin-left: 8%;
    margin-top: 15%;
  }
  .mob-user-id {
    flex: 35%;
    max-width: 68%;
    margin-left: 20px;
    height: 40px;
  }
  .user-profile .decription-arrow img {
    width: 10px;
    padding: 0 auto;
    margin-left: 8px;
  }
  .user-comment-pic {
    border-radius: 10px 40px 10px 40px;
    margin-top: 16px;
    margin-bottom: 14px;
  }
  .user-comment-pic img {
    width: 280px;
    height: 105px;
    border-radius: 10px 40px 10px 40px;
  }
  .mob-like-icon {
    height: 25px;
    margin-top: 21px;
    margin-bottom: 20px;
  }
  .share {
    width: 150px;
    height: 20px;
    margin-right: 23px;
    display: inline-block;
    font-size: 12px;
    color: #817889;
    font-weight: 700;
  }
  .share img {
    width: 16px;
  }
}

/*--............Side bar..........*/
.side-bar-wrapper {
  width: 415px;
  height: auto;
  min-height: 99vh;
  background-color: #fff;
  box-shadow: 0 3px 6px;
  margin: 0 auto;
  /* position: fixed;
    z-index: 11;
    right:6px;
    top:2px; */
}
.container-custom {
  width: 100%;
  margin: auto;
}
/*............Side bar/search..........*/
.search-back {
  width: 100%;
  height: 70px;
  margin: 0px 8px;
}
.search-bar {
  height: 43px;
  border: 1px solid #d5d5d5;
  border-radius: 28px;
  margin: 0 auto;
  display: flex;
}
.search-input {
  flex: 77%;
  max-width: 77%;
  margin-left: 20px;
  border: none;
}
.search-bar img {
  width: 18px;
  margin-left: 15px;
  margin-top: 12px;
}

.search-bar::placeholder {
  color: #959595;
  font-size: 14px;
  /* font-family: "IBM Plex Sans, Regular"; */
  font-family: "IBM Plex Sans";
  font-weight: 500;
}
.search-icon {
  flex: 10%;
  max-width: 10%;
}
.close-bar {
  float: right;
  background: #fff;
  border: none;
  font-size: 20px;
  margin-right: 10px;
}
::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}
::-webkit-scrollbar-track {
  background: #dcdcdc;
}
::-webkit-scrollbar-thumb {
  background: grey;
}
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

/*............Side bar/Assigned employees..........*/
.sidebar-employee-back {
  width: 100%;
  height: auto;
  margin-top: 27px;
  margin-bottom: 50px;
}
.sidebar-employee-title {
  width: 100%;
  height: 35px;
  font-size: 18px;
  color: #000000;
  border-bottom: 2px solid #f2f2f2;
  /* font-family: IBM Plex Sans, Bold; */
  font-family: "IBM Plex Sans";
  margin-top: 54px;
  font-weight: 600;
}
.sidebar-employee-details {
  width: 100%;
  height: 50px;
  margin-top: 15px;
  margin-bottom: 25px;
  display: flex;
}
.employee-image {
  flex: 18%;
  max-width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #0d0e10;
}
.employee-image img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.employee-detail {
  flex: 35%;
  max-width: 35%;
  height: 45px;
  margin: 0 13px;
}
.em-name {
  width: 100%;
  height: 20px;
  display: inline-block;
  font-size: 15px;
  color: #000000;
  /* font-family: IBM Plex Sans, Bold; */
  font-family: "IBM Plex Sans";
  font-weight: 600;
  overflow: hidden;
}
.em-rating {
  width: 35%;
  height: 20px;
  float: right;
}
.rating-dot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #ff6969;
  display: inline-block;
}
.em-designation {
  width: 100%;
  height: 19px;
  font-size: 14px;
  overflow: hidden;
  color: #6c6c6c;
}
.btn-task {
  flex: 20%;
  max-width: 95px;
  float: right;
  height: 31px;
  background: #f2f2f2;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  color: #6f6f6f;
  line-height: 31px;
  /* font-family: "IBM Plex Sans"; * font-family: Open Sans, Regular;*/
  font-family: "IBM Plex Sans";
  margin-top: 3%;
}
.btn-task .btn {
  padding-top: 0px;
}
.btn-task .btn:focus {
  box-shadow: none;
}

/*............Side bar/Unassigned employees..........*/
.unassigned-employee-back {
  width: 100%;
  height: 300px;
  background-color: aquamarine;
}
.modal-sidebar-header {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
  height: 62px;
  margin-top: 10%;
}
.modal-dialog {
  margin: 0;
  float: right;
}
.modal-sidebar-content {
  width: 83%;
}
.side-bar-wrapper .close {
  padding: 2%;
}

/*............Assign-team..........*/
.table {
  margin-top: 40px;
  width: 100%;
  height: auto;
  border: 1px solid #dedede;
  border-radius: 0 0 10px 10px;
}


/* .col-head-left {
    height:48px;
    background-color: #F1F1F1;
    color:#817889;
    font-size:14px;
    border:1px solid #DEDEDE;
    font-weight: 700;
}
.col-head-right {
    height:48px;
    color:#817889;
    font-size:14px;
    border:1px solid #DEDEDE;
    padding-left:4%;
    font-weight: 500;
    padding-top: 13px;
} */
.col-left {
  height: auto;
  background-color: #f1f1f1;
  color: #817889;
  font-size: 14px;
  border: 1px solid #dedede;
  font-weight: 700;
  padding-bottom: 18px;
}

.col-right {
  height: auto;
  color: #817889;
  font-size: 14px;
  border: 1px solid #dedede;
  padding-left: 4%;
  font-weight: 500;
  padding-top: 13px;
  padding-bottom: 18px;
}
.right-heading {
  padding-left: 20px;
  display: inline-block;
}
.red-circle {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: #c75c5c;
  display: inline-block;
  margin-left: 21px;
  margin-top: 13px;
}
.red-circle img {
  width: 13px;
  margin-left: 4px;
  margin-bottom: 6px;
}
.table-dots {
  float: right;
  margin-top: 10px;
  border: none;
  background: transparent;
  margin-right: 10px;
}
.table-dots img {
  width: 24px;
}
.pricing-icon {
  display: inline-block;
}
.pricing-icon img {
  width: 21px;
}
.table-breadcrumbs {
  width: 500px;
  height: 20px;
  margin-left: 10%;
  margin-top: 6%;
}

.mob-table-breadcrumbs {
  width: 500px;
  height: 20px;
  margin-left: 1.5%;
  margin-top: 6%;
}

.pricing-Back {
  width: 100%;
  height: auto;
  display: flex;
  margin-left: 10%;
}

.mob-pricing-Back {
  width: 100%;
  height: auto;
  display: flex;
  margin-left: 1.5%;
}

.pricing-text {
  color: #515c6f;
  max-width: 60%;
  font-size: 30px;
  font-family: "IBM Plex Sans";
  font-weight: 900;
  padding-left: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
.pricing-block {
  flex: 44%;
  max-width: 44%;
}
.pricing-tag {
  width: 72px;
  background: #7ed321;
  height: 23px;
  border-radius: 14px;
  font-size: 13px;
  color: #fff;
  font-family: "IBM Plex Sans";
  text-align: center;
  line-height: 25px;
  display: inline-block;
  margin-top: 5px;
}


.mark-task {
  flex: 12%;
  max-width: 12%;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  color: #ff6969;
  margin-right: 6%;
  margin-top: 10px;
}
.mark-task-button {
  font-family: "IBM Plex Sans";
  color: #ff6969;
  padding: 0;
  border: none;
  background: none;
}
.table-mark-task img {
  width: 18px;
  margin-right: 5px;
}

@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i&display=swap");

.web-address-input::placeholder {
  text-transform: uppercase;
  color: #515c6f;
  font-weight: 100;
  font-size: 15px;
}
/*......breadcrumbs.......*/
.des-all-tasks {
  width: 100%;
  height: 70px;
  padding: 16px;
}
.mai-logo {
  width: 10%;
  height: 38px;
  margin: 0;
  border-right: 1px solid #00000010;
}
.header-mai-logo {
  font-family: "IBM Plex", sans-serif;
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  margin: 0px 15px;
}
.separator {
  width: 1px;
  height: 38px;
  background: #fff;
}
.top-dropdown {
  border: none;
  background: #fff;
  color: #3e3f42;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.login-btn button {
  float: right;
  width: 110px;
  color: #fff;
  height: 38px;
  background: #ff6969;
  border-radius: 4px;
  border: 0;
}

.main-div {
  margin-top: 0px;
  margin-left: 50px;
  width: 95%;
}
.header-main {
  height: 80px;
  width: 190px;
}

ul.breadcrumb {
  padding: 0px 0px;
  list-style: none;
  margin-bottom: 0;
}
ul.breadcrumb li {
  display: inline;
  font-size: 12px;
}
ul.breadcrumb li + li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}
ul.breadcrumb li a {
  color: #000;
  text-decoration: none;
}
ul.breadcrumb li a:hover {
  color: #000;
}
.breadcrumb {
  background-color: transparent;
}

.page-head {
  font-size: 30px;
  color: #515c6f;
  font-weight: 700;
  font-family: "IBM Plex Sans";
}
.main-wrapper {
  margin: 30px 0 0 0;
}
.right-container {
  width: 300px;
  height: 100%;
  background: white;
  padding-bottom: 50px;
}

.right-container .sticked {
  position: sticky;
  top: 30px;
}

.urls {
  width: 190px;
  height: 200px;
  background: white;
  margin-top: 15px;
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  color: #0d0e10;
}
.checkbox a {
  color: #0d0e10;
  font-size: 11px;
  font-family: "IBM Plex Sans";
  flex: 75%;
  max-width: 75%;
}
.checkbox a:hover {
  text-decoration: none;
}
.heading-box {
  font-family: "IBM Plex Sans";
  font-size: 14px;
  color: #0d0e10;
  font-weight: 800;
}
input:focus {
  box-shadow: none;
  outline: none;
}
button:focus {
  outline: none;
  box-shadow: none;
}
.checkbox {
  display: flex;
  margin-top: 9px;
}
.checkboxInput-top {
  font-size: 11px;
  font-family: "IBM Plex Sans";
  flex: 10%;
  max-width: 10%;
  margin-top: 2px;
}
.checkbox-custom a {
  color: #ff6969;
}
.checkboxInput {
  flex: 10%;
  max-width: 10%;
  margin-top: 2px;
}
.checkbox small {
  font-size: 8px;
  font-family: "IBM Plex Sans";
  color: #0d0e10;
}
.checkbox-custom small {
  font-size: 8px;
  font-family: "IBM Plex Sans";
  color: #ff6969;
}
.assigned-to {
  width: 190px;
  height: 95px;
  background: white;
  margin-top: 15px;
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  color: #0d0e10;
}
.created-by {
  width: 190px;
  height: 158px;
  background: white;
  margin-top: 15px;
  border-bottom: 1px solid lightgray;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  color: #0d0e10;
}

.tasks-for-website {
  height: 18px;
  width: calc(100%);
  background: white;
  color: #ff6969;
  font-size: 14px;
  font-family: "IBM Plex Sans", "sans serif";
}

.main-tasks-container {
  width: calc(100% - 300px);
}

.tasks-wrapper {
  width: 100%;
}

.task-container {
  width: 100%;
  height: 70px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 12px #d3d3d3;
  margin-top: 7px;
  display: flex;
  align-items: center;
}

.task-item {
  flex: 3%;
  max-width: 3%;
  margin-left: 2%;
}

/* .task-name {
  flex: 35%;
  max-width: 35%;
  word-break: break-word;
  font-size: 16px;
  font-weight: 700;
  font-family: "IBM Plex Sans";
  color: #0d0e10;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 3px 0;
  margin-left: 10px;
} */

.task-id {
  flex: 50%;
  max-width: 5%;
  word-break: break-word;
  font-size: 14px;
  font-weight: 400;
  font-family: "IBM Plex Sans";
  color: #0d0e10;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
  overflow: hidden;
  padding: 0 30px 0 5px;
  margin:  0 10px 0 0;
}

.mob-task-id {
  word-break: break-word;
  font-size: 13px;
  font-weight: 400;
  font-family: "IBM Plex Sans";
  color: #0d0e10;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.task-significance {
  flex: 10%;
  max-width: 7%;
  height: 25px;
  border-radius: 14px;
  font-size: 13px;
  color: #fff;
  font-family: "IBM Plex Sans";
  text-align: center;
  line-height: 25px;
}

.task-significance.green {
  background: #7ed321;
}

.pages-affected {
  flex: 20%;
  max-width: 15%;
  color: #515c6f;
  font-size: 16px;
  text-align: center;
  padding: 3px 20px;
  margin: 0px;
}

/* .traffic-affected {
  flex: 28%;
  max-width: 28%;
  font-size: 16px;
  font-family: "IBM Plex Sans";
  color: #515c6f;
} */

.message-counter-box {
  flex: 5%;
  max-width: 5%;
}

.assigned-container {
  margin: -5px 0 !important;
  max-width: 50%;
  position: relative;
}

.assignedperson-one {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: inline-block;
  top: 0px;
  position: relative;
  z-index: 2;
  background: url(../image/person-1.jpg);
  border: 0px solid #fff;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-size: cover;
}

.assignedperson-rest {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: inline-block;
  top: 0px;
  left: -20px;
  position: relative;
  z-index: 1;
  background: url(../image/person-2.png);
  border: 2px solid #fff;
  border-radius: 30px;
  background-repeat: no-repeat;
  background-size: cover;
}

.task-more {
  flex: 50%;
  max-width: 3%;
  padding: 5px 10px;
}

.connected-apps-container {
  width: 100%;
  height: 175px;
  margin-top: 14px;
  overflow-y: hidden;
  overflow-x: scroll;
  white-space: nowrap;
  max-width: 100%;
}

.apps-container {
  margin-top: 12px;
  height: 100%;
  width: 100%;
}

.app-box {
  display: inline-block;
  width: 140px;
  height: 118px;
  background: #352641;
  border: none;
  border-radius: 30px;
}

.app-image-div {
  width: 50%;
  height: 50px;
  margin-top: 14px;
  margin-left: 22px;
  position: relative;
}

.app-img {
  position: absolute;
  width: 45px;
}

.app-name-div {
  width: 50%;
  height: 30px;
  margin-left: 22px;
  position: relative;
  font-size: 12px;
  color: #ffffff;
  line-height: 1.1;
}

.app-name {
  position: absolute;
}

.message svg .a {
  fill: #fff;
}

.next-button-container {
  width: 100%;
  height: 170px;
  padding: 15px;
}

.btn-next {
  margin-top: 43px;
  width: 330px;
  color: #fff;
  height: 46px;
  background: #d4d6dc;
  border-radius: 30px;
  border: 0;
  box-shadow: 0 5px 10px #ff696940;
  color: #515c6f;
  font-size: 14px;
  position: relative;
  font-family: "IBM Plex Sans";
  font-weight: 600;
}

.btn-next .right-icon-container {
  background: #fff;
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.btn-next .right-icon-container img {
  margin-top: 3px;
}

.compare-competitors-wrapper {
  width: 100%;
  height: 300px;
  padding: 15px;
  font-size: 14px;
  font-weight: 800;
}

.compare-competitors-container {
  display: inline-block;
  margin-top: 43px;
  width: 345px;
  color: #fff;
  height: 60px;
  background: #fff;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 5px 10px #e7eaf0;
  color: #515c6f;
  font-size: 14px;
  position: relative;
}

.compare-competitors-container .left-icon-container {
  display: inline-block;
  width: 30px;
  height: 20px;
  text-align: center;
  margin-top: 19px;
  margin-left: 10px;
}

.compare-competitors-container .left-icon-container img {
  width: 20px;
}

.compare-competitors-container .right-icon-container {
  display: inline-block;
  height: 100%;
  width: calc(100% - 70px);
  position: absolute;
}

.web-address-label {
  display: block;
  height: 30%;
  width: 100%;
  font-size: 12px;
  color: #515c6f;
  text-transform: uppercase;
  margin-left: 1%;
  opacity: 0.5;
  font-weight: 500;
  margin-top: 8px;
}

.web-address-input {
  border: none;
  width: 100%;
  font-size: 15px;
  left: 1%;
  margin-left: 1%;
}

.compare-button-container {
  display: inline-block;
  margin-left: 8px;
  position: relative;
  bottom: 0;
}

.btn-compare {
  margin-top: 43px;
  width: 330px;
  color: #fff;
  height: 46px;
  background: #ff6969;
  border-radius: 30px;
  border: 0;
  box-shadow: 0 5px 10px #ff696964;
  color: #fff;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  text-transform: uppercase;
}

.btn-compare .right-icon-container {
  background: #fff;
  width: 28px;
  height: 28px;
  border: none;
  border-radius: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.btn-compare .right-icon-container img {
  margin-top: 3px;
}

.compared-competitor-wrapper {
  display: inline-block;
  margin-top: 43px;
  width: 350px;
  height: 36px;
  background: #d4d6dc;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 5px 10px #00000016;
  color: #515c6f;
  font-size: 14px;
  position: relative;
}

.compared-competitor-wrapper .left-icon-container {
  display: inline-block;
  width: 30px;
  height: 22px;
  text-align: center;
  margin-top: 5px;
  margin-left: 10px;
}

.compared-competitor-wrapper .web-address-entered-container {
  display: inline-block;
  background: #d4d6dc;
  height: 36px;
  width: calc(100% - 75px);
  position: absolute;
}

.web-address-entered {
  width: 100%;
  font-size: 13px;
  line-height: 34px;
  text-transform: uppercase;
  color: #515c6f;
}

.compared-competitor-wrapper .right-icon-container {
  display: inline-block;
  width: 30px;
  height: 22px;
  text-align: center;
  margin-top: 5px;
  float: right;
  margin-right: 10px;
}

.compared-competitor-wrapper .right-icon-container img {
  width: 20px;
}

/* Pre-Footer and Footer Items CSS - Written on Aug 18, 2019 by Ayush */
.pre-footer-container {
  width: 100%;
  height: 120px;
  margin: 0;
  padding: 38px 70px;
}
.pre-foot-mai-logo {
  display: inline-block;
  width: 7%;
  height: 40px;
  background: #fff;
  margin: 0;
  line-height: 40px;
  font-size: 32px;
  font-weight: 900;
  padding: 0;
}
.pre-foot-mai-links {
  display: inline-block;
  width: 55%;
  height: 40px;
  line-height: 40px;
  margin: 0 75px;
  padding: 0;
  text-align: right;
}
.pre-foot-item {
  color: #666666;
  font-size: 16px;
  line-height: 40px;
  display: inline;
  margin: 0px 5px;
}
.pre-footer-links {
  color: #666666;
}
.pre-foot-mai-copyright {
  display: inline-block;
  width: 20%;
  height: 40px;
  margin: 0;
  line-height: 40px;
  margin: 0px 5px;
  padding: 0;
  text-align: right;
}
.pre-foot-copy {
  color: #6e6e6e;
  font-size: 12px;
  line-height: 40px;
  margin: 0px 5px;
  text-align: center;
}

/* Footer container  */
.footer-container {
  width: 100%;
  height: 367px;
  background: #f8f9fb;
  margin: 0;
  padding: 80px;
}
.footer-seo-items-container {
  width: 15%;
  height: 200px;
}
.footer-items-heading {
  width: 100%;
  height: 20px;
  font-size: 18px;
  font-family: "IBM Plex Sans";
  color: #666666;
  font-weight: bold;
}
.footer-items {
  margin-top: 20px;
}
.footer-item {
  width: 100%;
  height: 20px;
  margin: 10px 0;
}
.footer-item a {
  font-family: "IBM Plex Sans";
  font-weight: 500;
  font-size: 16px;
  color: #666666;
}
.footer-local-items-container {
  width: 18%;
  height: 200px;
  margin-left: 55px;
}

.footer-marketing-items-container {
  width: 21%;
  height: 200px;
  margin-left: 55px;
}

.footer-separation {
  width: 0px;
  height: 200px;
  border: 1px solid #70707052;
  border-radius: 4px;
  margin-left: 45px;
}
.footer-subscribe-items-container {
  width: 25%;
  height: 200px;
  margin-left: 40px;
  margin-top: 10px;
}
.subscribe-blog-container {
  width: 100%;
  height: 20px;
  font-size: 16px;
  font-family: "IBM Plex Sans";
  color: #666666;
  font-weight: 300;
}
.subscribe-body-container {
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: 20px;
  font-size: 14px;
  font-family: "IBM Plex Sans";
  color: #666666;
  font-weight: 200;
}
.subscribe-btn {
  margin-top: 43px;
  width: 240px;
  color: #fff;
  height: 46px;
  background: #ff6969;
  border-radius: 30px;
  border: 0;
  box-shadow: 0 5px 10px #ff696964;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
}
.mob-view {
  display: none;
}
.desktop-view {
  display: block;
}

@media screen and (max-width: 1250px) {
  .desktop-view {
    display: none;
  }
  .mob-view {
    display: block;
    padding: 10px;
    padding-top: 2px;
    padding-bottom: 0px;
  }
  .mob-all-tasks {
    height: 56px;
    /* background: red; */
    /* padding: 0 20px; */
    width: 95%;
    padding-left: 9px;
  }

  .heading-container {
    float: left;
    font-size: 26px;
    line-height: 56px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    vertical-align: middle;
  }
  .mob-page-heading {
    display: inline-block;
    font-family: "IBM Plex Sans";
  }
  .hamburger-container {
    float: right;
    margin: 20px 0;
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    vertical-align: middle;
  }
  .mob-all-tasks-tag {
    display: inline-block;
    height: 30px;
    width: 86px;
    background: -webkit-linear-gradient(right, #3905cd, #9014ee) !important;
    text-align: center;
    line-height: 30px;
    border: none;
    border-radius: 4px;
    color: #fff;
    box-shadow: 4px 4px 12px 2px #3905cd45;
    font-weight: 700;
    font-size: 12px;
  }
  .mob-seo-tasks {
    display: inline-block;
    height: 30px;
    width: 86px;
    background: #fff;
    text-align: center;
    line-height: 30px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    color: #0d0e10;
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 5px;
    font-weight: 700;
  }
  .task-head {
    height: 35px;
    width: calc(100%);
    font-size: 12px;
    font-family: "IBM Plex Sans";
    font-weight: 800;
  }
  .main-tasks-container {
    width: calc(100%);
  }
  .tasks-container {
    width: 100%;
    height: 290px;
  }
  .tasks-white-box {
    width: 100%;
    height: 70px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 12px #d3d3d3;
    margin-top: 5px;
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  .right-sign {
    flex: 10%;
    max-width: 10%;
    margin-left: 3%;
  }
  .white-box-text {
    flex: 55%;
    max-width: 55%;
    font-size: 13px;
    font-weight: 600;
    font-family: "IBM Plex Sans";
    color: #0d0e10;
  }
  .white-box-redTag {
    flex: 22%;
    max-width: 22%;
    font-size: 11px;
    font-family: "IBM Plex Sans";
    text-align: center;
  }
  .white-box-arrow {
    flex: 10%;
    max-width: 10%;
  }
  .connected-apps-container {
    width: 100%;
    height: 175px;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    max-width: 100%;
    margin-top: 35px;
  }
  .next-button-container {
    width: 100%;
    height: 245px;
    margin-top: 35px;
  }
  .wesite-container {
    margin-top: 20px;
    margin-left: 0px;
    width: 345px;
    color: #fff;
    height: 60px;
    background: #fff;
    border-radius: 10px;
    border: 0;
    box-shadow: 0 5px 10px #e7eaf0;
    color: #515c6f;
    font-size: 14px;
    position: relative;
  }
  .left-icon-container {
    display: inline-block;
    width: 30px;
    height: 25px;
    text-align: center;
    margin-top: 19px;
    margin-left: 10px;
  }
  .right-icon-container {
    display: inline-block;
    height: 100%;
    width: calc(100% - 70px);
    position: absolute;
  }
  .web-address-label {
    display: block;
    height: 30%;
    width: 100%;
    font-size: 12px;
    color: #515c6f;
    text-transform: uppercase;
    margin-left: 1%;
    opacity: 0.5;
    font-weight: 500;
    margin-top: 8px;
  }
  .web-address-input {
    border: none;
    width: 100%;
    font-size: 15px;
    left: 1%;
    margin-left: 1%;
  }
  .mob-filters-container {
    height: 65px;
    padding: 5px 1px;
    overflow: hidden;
    width: 100%;
  }
  .apps-container {
    margin-top: 12px;
    height: 100%;
    width: 100%;
  }
  .app-box {
    display: inline-block;
    width: 140px;
    height: 118px;
    background: #352641;
    border: none;
    border-radius: 30px;
    margin-right: 10px;
  }
  .app-black-base {
    width: 50%;
    height: 50px;
    margin-top: 14px;
    margin-left: 22px;
    position: relative;
  }
  .app-black-base img {
    position: absolute;
  }
  .app-black-base-text {
    width: 50%;
    height: 30px;
    margin-left: 22px;
    position: relative;
    font-size: 12px;
    color: #ffffff;
    line-height: 1.1;
  }
  .app-black-base-text span {
    position: absolute;
  }
  .add-competitor-button {
    margin-top: 12px;
    width: 330px;
    color: #fff;
    height: 50px;
    background: #ff6969;
    border-radius: 30px;
    border: 0;
    box-shadow: 0 5px 10px #ff696964;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    text-transform: uppercase;
    line-height: 50px;
    margin-left: 8px;
    position: relative;
  }
  .appButton-right-icon {
    background: #fff;
    width: 28px;
    height: 28px;
    border: none;
    border-radius: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .appButton-right-icon img {
    width: 7px;
    margin-bottom: 23px;
  }
  .your-competitor-container {
    display: inline-block;
    margin-top: 12px;
    width: 350px;
    height: 36px;
    background: #d4d6dc;
    border-radius: 10px;
    border: 0;
    box-shadow: 0 5px 10px #00000016;
    color: #515c6f;
    font-size: 14px;
    position: relative;
  }
  .your-comp-left-icon {
    display: inline-block;
    width: 30px;
    height: 22px;
    text-align: center;
    margin-top: 5px;
    margin-left: 10px;
  }
  .web-address-entered-container {
    display: inline-block;
    background: #d4d6dc;
    height: 36px;
    width: calc(100% - 75px);
  }
  .web-address-entered {
    width: 100%;
    font-size: 13px;
    line-height: 34px;
    text-transform: uppercase;
    color: #515c6f;
  }
  .your-comp-right-icon {
    display: inline-block;
    width: 30px;
    height: 22px;
    text-align: center;
    margin-top: 5px;
    margin-right: 10px;
    position: absolute;
  }
  .add-button {
    width: 56px;
    height: 56px;
    background: #352641;
    border-radius: 40px;
    border: 0;
    bottom: 40px;
    margin-left: 24px;
    position: fixed;
    right: 20px;
  }
  .sub-tags {
    font-size: 13px;
    color: #b5b5b6;
  }
}

@media screen and (max-width: 1250px) {
  /* .......Modal box.......... */
  .modal-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* padding: 1rem; */
  }

  /*..........Filters..............  */
  .Filters {
    width: 100%;
    height: auto;
  }

  .filters-main-container {
    width: calc(100%);
    margin-top: 43px;
  }

  .main-container {
    width: 93%;
    height: auto;
    /* background-color: aliceblue; */
    margin: 0 auto;
  }

  .filter-sub-header {
    width: 100%;
    height: 18px;
    /* background-color: aqua; */
    font-size: 14px;
    font-family: "IBM Plex Sans";
    color: #0d0e10;
  }

  /*..........Filters/ just show..............  */
  .filters-just-show-container {
    width: 100%;
    height: auto;
    /* background: #7ED321; */
    border-bottom: 1px solid lightgray;
    margin-bottom: 8px;
  }

  .filters-justshow-tag {
    width: 100%;
    height: auto;
    margin-top: 8px;
    margin-bottom: 19px;
  }

  .just-show-button {
    width: 177px;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    font-size: 11px;
    font-family: "IBM Plex Sans";
    color: #0d0e10;
    margin-right: 11px;
    margin-top: 8px;
    display: inline-block;
    text-align: center;
    line-height: 42px;
  }

  /*..........Filters/ priority/status..............  */
  .filters-priority-container {
    width: 100%;
    height: auto;
    border-bottom: 1px solid lightgray;
    margin-bottom: 8px;
  }

  .filters-priority-button-container {
    width: 100%;
    overflow-x: auto;
    display: flex;
    overflow-y: hidden;
    margin: 24px 0;
  }

  

  

  /*..........Filters/ status..............  */
  /* .filters-status-container {
      width: 100%;
      height: 80px;
      background: #FF6969;
      border-bottom: 1px solid lightgray;
      font-size: 14px;
      font-family: "IBM Plex sans";
      color: #0d0e10;
  } */

  /*..........Filters/ urls..............  */
  .filters-urls-container {
    width: 100%;
    height: 107;
    border-bottom: 1px solid lightgray;
  }

  .filters-white-box {
    width: 100%;
    height: 70px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 12px #d3d3d3;
    margin-top: 26px;
    margin-bottom: 38px;
    display: flex;
    align-items: center;
  }

  .filters-url-text {
    flex: 85%;
    max-width: 85%;
    font-size: 16px;
    font-weight: 600;
    font-family: "IBM Plex Sans";
    color: #0d0e10;
    padding-left: 7%;
    text-align: left;
  }
  .btn.focus,
  .btn:focus {
    outline: none;
    box-shadow: none;
  }

  .filters-url-arrow {
    flex: 10%;
    max-width: 10%;
    text-align: center;
  }

  .filters-url-arrow img {
    widows: 22px;
  }

  /*..........Filters/ assigned and created by..............  */
  .filters-assigned-container {
    width: 100%;
    height: 250px;
    border-bottom: 1px solid lightgray;
  }

  .filter-subhead-2 {
    font-size: 14px;
    font-family: "IBM Plex sans, SemiBold";
    color: #0d0e10;
    margin-top: 22px;
    font-weight: 700;
  }

  .filters-checkbox {
    display: flex;
    margin-top: 9px;
  }

  form {
    width: 100%;
  }

  .filters-checkboxInput {
    flex: 10%;
    max-width: 10%;
    margin-top: 5px;
    margin-left: 13px;
    width: 16px;
    height: 16px;
    border: 1px solid #d7d8da;
    border-radius: 2px;
  }

  .filters-checkbox a {
    color: #0d0e10;
    text-decoration: none;
    font-size: 16px;
    margin-left: 25px;
    font-family: "IBN Sans";
  }

  /*..........Filters/ created by..............  */
  /* .filters-created-container {
      width: 100%;
      height: 158px;
      background: yellow;
      border-bottom: 1px solid lightgray;
      font-size: 14px;
      font-family: "IBM Plex sans";
      color: #0d0e10;
  } */

  /*..........Filters/ button..............  */
  .filters-button-container {
    width: 100%;
    height: 59px;
    background: #ff6969;
    box-shadow: 0 6px -6px #d7d8da;
  }

  .filters-button-container a {
    font-size: 15px;
    font-family: "IBM Plex Sans";
    color: #fff;
    text-decoration: none;
  }
  .modal-dialog {
    margin: 0;
  }

  .url-btn {
    display: flex;
    width: 100%;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
  }
  .close-button {
    font-size: 26px;
    line-height: 56px;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    vertical-align: middle;
  }

  /*..........Page..............  */
  .page {
    width: 100%;
    height: auto;
  }

  .page-main-container {
    width: calc(100%);
    margin-top: 47px;
  }

  .page-inner-container {
    width: 93%;
    height: auto;
    /* background-color: aliceblue; */
    margin: 0 auto;
  }
  /*..........Page/ Search ..............  */

  .page-search-container {
    width: 100%;
    height: 45px;
    margin-bottom: 25px;
  }
  .search-wrpper {
    width: 100%;
    height: 44px;
    border: 1px solid #d7d8da;
    border-radius: 4px;
    display: flex;
  }
  .search-icon {
    flex: 15%;
    max-width: 15%;
  }
  .search-icon img {
    width: 17px;
    margin: 14px 18px;
  }
  .input-custom {
    border: none;
    flex: 85%;
    max-width: 85%;
    font-size: 15px;
    font-family: IBM Plex Sans, Regular;
    color: lightgray;
  }

  /*..........Page/ main ..............  */

  .page-container {
    width: 100%;
    height: 69vh;
  }

  .page-white-box {
    width: 100%;
    height: 70px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 12px #d3d3d3;
    margin-top: 26px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
  }

  .page-checkbox {
    display: flex;
    margin-top: 9px;
  }

  form {
    width: 100%;
  }

  .page-checkboxInput {
    flex: 10%;
    max-width: 10%;
    margin-top: 5px;
    margin-left: 13px;
    width: 16px;
    height: 16px;
    border: 1px solid #d7d8da;
    border-radius: 2px;
  }

  .page-checkbox a {
    color: #0d0e10;
    text-decoration: none;
    font-size: 16px;
    margin-left: 25px;
    font-family: IBM Plex Sans, Regular;
  }

  /*..........Page/ button..............  */
  .page-button-container {
    width: 100%;
    height: 59px;
    background: -webkit-linear-gradient(right, #3905cd, #9014ee);
    box-shadow: 0 6px -6px #d7d8da;
    /* position: absolute; */
    bottom: 0;
  }

  .page-button-container a {
    font-size: 15px;
    font-family: IBM Plex Sans, SemiBold;
    color: #fff;
    text-decoration: none;
  }
  /*..........Main Filtered..............  */

  .main-wrapper {
    width: 93%;
    height: auto;
    margin: 0 auto;
  }

  .mainFiltered-container {
    height: 65px;
    padding: 5px 1px;
    overflow-y: hidden;
    overflow-x: scroll;
    width: 100%;
  }

  .mainFiltered-inner {
    width: 100%;
    overflow-x: auto;
    display: flex;
    overflow-y: hidden;
  }

  .mainFiltered-all-tag {
    display: inline-block;
    height: 30px;
    width: 86px;
    background: #ff6969;
    text-align: center;
    line-height: 30px;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
  }

  .mainFiltered-other-tag {
    display: inline-block;
    height: 30px;
    width: 86px;
    background: #fff;
    text-align: center;
    line-height: 30px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    color: #0d0e10;
    font-size: 15px;
    text-transform: uppercase;
    margin-left: 5px;
    font-weight: 700;
  }

  .mainFiltered-section-container {
    width: calc(100%);
  }
  /* <!-- mainFiltered/sub-heading --> */
  .mainFiltered-head {
    height: 20px;
    width: calc(100%);
    font-size: 12px;
    font-family: "IBN Sans";
    font-weight: 800;
  }

  .mainFiltered-div {
    width: 100%;
    height: 501px;
    overflow-y: auto;
  }

  .mainFiltered-white-box {
    width: 100%;
    height: 70px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 5px 12px #d3d3d3;
    margin-top: 15px;
    display: flex;
    align-items: center;
  }

  .mainFiltered-right-sign {
    flex: 10%;
    max-width: 10%;
    margin-left: 3%;
  }

  .mainFiltered-white-box-text {
    flex: 55%;
    max-width: 55%;
    font-size: 13px;
    font-weight: 600;
    font-family: IBM Plex Sans, SemiBold;
    color: #0d0e10;
  }

  .mainfiltered-sub-tags {
    font-size: 13px;
    color: #b5b5b6;
  }

  .mainFiltered-white-box-redTag {
    flex: 22%;
    max-width: 22%;
    font-size: 11px;
    font-family: IBM Plex Sans, SemiBold;
    text-align: center;
  }

  .mainfiltered-white-box-arrow {
    flex: 10%;
    max-width: 10%;
  }

  /*..........mainFilters/ button..............  */
  .mainFiltered-button-container {
    width: 100%;
    height: 59px;
    background: #ff6969;
    box-shadow: 0 6px -6px #d7d8da;
    margin-top: 3%;
  }

  .mainFiltered-button-container a {
    font-size: 15px;
    font-family: IBM Plex Sans, SemiBold;
    color: #fff;
    text-decoration: none;
  }
  .modal-sm {
    max-width: 100%;
  }
  .modal-dialog {
    max-width: 100%;
  }
  .modal-des {
    padding-left: 0;
    padding-right: 0 !important;
  }

}
