@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Muli:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");


.web-address-input::placeholder {
    text-transform: uppercase;
    color: #515C6F;
    font-weight: 100;
    font-size: 15px;
}
/*......breadcrumbs.......*/
.des-all-tasks {
    width: 100%;
    height: 70px;
    padding: 16px;
}
.mai-logo {
    width: 10%;
    height: 38px;
    margin: 0;
    border-right: 1px solid #00000010;
}
.header-mai-logo {
    font-family: 'Roboto', sans-serif;
    height: 20px;
    font-size: 16px;
    font-weight: 500;
    margin: 0px 15px;
}
.separator {
    width: 1px;
    height: 38px;
    background: #fff;
}
.top-dropdown {
    border: none;
    background: #fff;
    color: #3E3F42;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 600;
}
.login-btn button {
    float: right;
    width: 100px;
    color: #fff;
    height: 35px;
    background: #ff6969;
    border-radius: 4px;
    border: 0;
}

.register-btn button {
    float: right;
    width: 100px;
    height: 36px;
    color: #ff6969;
    border: 1px solid #ff6969 !important;
    border-radius: 4px;
    border: 0;
}

.main-div {
    margin-top: 73px;
    margin-left: 50px;
    width: 95%;
}
.header-main {
    height: 80px; width: 100%;
}

ul.breadcrumb {
    padding: 0px 0px;
    list-style: none;
    margin-bottom: 0;
}
ul.breadcrumb li {
    display: inline;
    font-size: 12px;
}
ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
}
ul.breadcrumb li a {
    color: #000;
    text-decoration: none;
}
ul.breadcrumb li a:hover {
    color: #000;
}
.breadcrumb {
    background-color: transparent;
}

.page-head {
    font-size: 30px;
    color:#515C6F;
    font-weight: 700;
    font-family: Bebas Neue, Bold;
}
.main-wrapper {
    margin: 30px 0 0 0;
}
.right-container {
    width: 300px; height: 100%; background: white;
}

.urls {
    width: 190px; height: 200px; background: white; margin-top: 15px;border-bottom:1px solid lightgray; font-size: 14px; font-family: IBM Plex Sans, SemiBold;color:#0D0E10;
}
.checkbox a {
    color:#0D0E10;
    font-size: 11px;
    font-family: IBM Plex Sans, SemiBold;
    flex: 75%;
    max-width: 75%;
}
.checkbox a:hover {
    text-decoration: none;
}
.heading-box {
    font-family: IBM Plex Sans, SemiBold;
    font-size: 14px;
    color:#0D0E10;
    font-weight: 800;
}
input:focus {
    box-shadow: none;
    outline: none;
}
button:focus {
    outline: none;
    box-shadow: none;
}
.checkbox {
    display: flex;
    margin-top: 9px;
}
.checkboxInput-top  {
    font-size: 11px; font-family: IBM Plex Sans, SemiBold;
    flex: 10%;
    max-width: 10%;
    margin-top: 2px;
}
 .checkbox-custom a {
    color: #FF6969;
}
.checkboxInput {
    flex: 10%;
    max-width: 10%;
    margin-top: 2px;
}
.checkbox small {
    font-size: 8px; font-family: IBM Plex Sans, Regular; color:#0D0E10;
}
.checkbox-custom small {
    font-size: 8px; font-family: IBM Plex Sans, Regular; color:#FF6969;
}
.assigned-to {
    width: 190px; height: 95px; background: white; margin-top: 15px;border-bottom:1px solid lightgray; font-size: 14px; font-family: IBM Plex Sans, SemiBold;color:#0D0E10;
}
.created-by {
    width: 190px; height: 158px; background: white; margin-top: 15px;border-bottom:1px solid lightgray; font-size: 14px; font-family: IBM Plex Sans, SemiBold;color:#0D0E10;
}

.message svg .a {
    fill: #fff;
}

/* Pre-Footer and Footer Items CSS - Written on Aug 18, 2019 by Ayush */
.pre-footer-container {
 display: flex;
 justify-content: space-between;
 padding: 38px 70px;
}
.pre-foot-mai-logo {
 display: inline-block;
 width: 7%;
 height: 40px;
 background: #fff;
 margin: 0;
 line-height: 40px;
 font-size: 32px;
 font-weight: 900;
 padding: 0;
}
.pre-foot-mai-links {
 display: inline-block;
 width: 55%;
 height: 40px;
 line-height: 40px;
 margin: 0 75px;
 padding: 0;
 text-align: left;
}
.pre-foot-item {
 color: #666666;
 font-size: 16px;
 line-height: 40px;
 display: inline;
 margin: 0px 5px;
}
.pre-footer-links {
 color: #666666;
}
.pre-foot-mai-copyright {
 display: inline-block;
 width: 30%;
 height: 40px;
 margin: 0;
 line-height: 40px;
 margin: 0px 5px;
 padding: 0;
 text-align: right;
}
.mai-pre-foot-copy {
    color: #6e6e6e;
    font-size: 12px;
    line-height: 20px;
    margin: 0px 5px;
    text-align: center;
}

/* Footer container  */
.footer-container {
 width: 100%;
 height: 367px;
 background: #f8f9fb;
 margin: 0;
 padding: 80px;
}
.footer-seo-items-container {
 width: 15%;
 height: 200px;
}
.footer-items-heading {
 width: 100%;
 height: 20px;
 font-size: 18px;
 font-family: "Montserrat", sans-serif;
 color: #666666;
 font-weight: bold;
}
.footer-items {
 margin-top: 20px;
}
.footer-item {
 width: 100%;
 height: 20px;
 margin: 10px 0;
}
.footer-item a {
 font-family: "Source Sans Pro", sans-serif;
 font-weight: 500;
 font-size: 16px;
 color: #666666;
}
.footer-local-items-container {
 width: 18%;
 height: 200px;
 margin-left: 55px;
}

.footer-marketing-items-container {
    width: 21%;
    height: 200px;
    margin-left: 55px;
}

.footer-separation {
 width: 0px;
 height: 200px;
 border: 1px solid #70707052;
 border-radius: 4px;
 margin-left: 45px;
}
.footer-subscribe-items-container {
 width: 25%;
 height: 200px;
 margin-left: 40px;
 margin-top: 10px;
}
.subscribe-blog-container {
 width: 100%;
 height: 20px;
 font-size: 16px;
 font-family: "Montserrat", sans-serif;
 color: #666666;
 font-weight: 300;
}
.subscribe-body-container {
 position: relative;
 margin-top: 20px;
 width: 100%;
 height: 20px;
 font-size: 14px;
 font-family: "Montserrat", sans-serif;
 color: #666666;
 font-weight: 200;
}
.subscribe-btn {
 margin-top: 43px;
 width: 240px;
 color: #fff;
 height: 46px;
 background: #ff6969;
 border-radius: 30px;
 border: 0;
 box-shadow: 0 5px 10px #ff696964;
 color: #fff;
 font-size: 14px;
 text-transform: uppercase;
}
.mob-view {
    display: none;
}
.desktop-view {
    display: block
}

/*..........Mobile View/ Media Query/ August 22, 2019.............*/
@media screen and (max-width: 1250px) {
    .desktop-view {
        display: none;
    }
    .mob-view {
        display: block;
    }
     .mob-all-tasks {
        height: 56px;
        /* background: red; */
        padding: 0 20px;
    }
    .heading-container {
        float: left;
        font-size: 26px;
        line-height: 56px;
        justify-content: center;
        justify-items: center;
        align-items: center;
        align-content: center;
        vertical-align: middle;
    }
    .mob-page-heading {
        display: inline-block;
        font-family: Bebas Neue, Bold;
    }
    .hamburger-container {
        float: right;
        margin: 20px 0;
        display: grid;
        justify-content: center;
        justify-items: center;
        align-items: center;
        align-content: center;
        vertical-align: middle;
    }
    .mob-all-tasks-tag {
        display: inline-block;
        height: 30px;
        width: 86px;
        background: -webkit-linear-gradient(right, #3905CD, #9014EE) !important;
        text-align: center;
        line-height: 30px;
        border: none;
        border-radius: 4px;
        color: #fff;
        box-shadow: 4px 4px 12px 2px #3905CD45;
        font-weight: 700;
        font-size:12px;
    }
    .mob-seo-tasks {
        display: inline-block;
        height: 30px;
        width: 86px;
        background: #fff;
        text-align: center;
        line-height: 30px;
        border: 1px solid #D8D8D8;
        border-radius: 4px;
        color: #0D0E10;
        font-size: 12px;
        text-transform: uppercase;
        margin-left: 5px;
        font-weight: 700;
    }
    .task-head {
        height: 35px;
        width: calc(100%);
        font-size: 16px;
        font-family:IBM Plex Sans, regular;
    }
    .main-tasks-container {
        width: calc(100%);
    }
    .tasks-container {
        width: 100%;
        height: 290px;
    }
    .tasks-white-box {
        width:100%;
        height:80px;
        background: #fff;
        border-radius:8px;
        box-shadow: 0 2px 12px #d3d3d3;
        margin-top: 5px;
        display: flex;
        align-items: center;
    }
    .right-sign {
        flex:10%;
        max-width:10%;
        margin-left:3%;
    }
    .white-box-text{
        flex:55%;
        max-width:55%;
        font-size: 15px;
        font-weight:600;
        font-family: IBM Plex Sans, SemiBold;
        color:#0D0E10;
    }
    .white-box-redTag {
        flex:22%;
        max-width:22%;
        font-size: 15px;
        font-family: IBM Plex Sans, SemiBold;
        text-align: center;
    }
    .white-box-arrow {
        flex:10%;
        max-width:10%;
    }
    .connected-apps-container {
        width: 100%;
        height: 175px;
    }
    .next-button-container {
        width: 100%;
        height: 225px;
    }
    .wesite-container {
        margin-top: 20px;
        width: 345px;
        color: #fff;
        height: 60px;
        background: #fff;
        border-radius: 10px;
        border: 0;
        box-shadow: 0 5px 10px #e7eaf0;
        color: #515c6f;
        font-size: 14px;
        position: relative;
        margin-left: 15px;
    }
    .left-icon-container {
        display: inline-block;
        width: 30px;
        height: 25px;
        text-align: center;
        margin-top: 19px;
        margin-left: 10px;
    }
    .right-icon-container {
        display: inline-block;
        height: 100%;
        width: calc(100% - 70px);
        position: absolute;
    }
    .web-address-label {
        display: block;
        height: 30%;
        width: 100%;
        font-size: 12px;
        color: #515c6f;
        text-transform: uppercase;
        margin-left: 1%;
        opacity: 0.5;
        font-weight: 500;
        margin-top: 8px;
    }
    .web-address-input {
        border: none;
        width: 100%;
        font-size: 15px;
        left:1%;
        margin-left: 1%;
    }
    .mob-filters-container {
        height: 48px;
        padding: 5px 1px;
        overflow: hidden;
        width: 100%;
    }
    .heading-box {
        font-size: 16px;
    }
    .apps-container {
        margin-top: 12px;
        height: 100%;
        width: 100%;
    }
    .app-box {
        display: inline-block;
        width: 140px;
        height: 118px;
        background: #352641;
        border: none;
        border-radius: 30px;
        margin-right: 10px;
    }
    .app-black-base {
        width: 50%;
        height:50px;
        margin-top: 14px;
        margin-left: 22px;
        position: relative;
    }
    .app-black-base img {
        position:absolute;
    }
    .app-black-base-text {
        width: 50%;
        height:30px;
        margin-left: 22px;
        position: relative;
        font-size: 12px;
        color:#FFFFFF;
        line-height: 1.1;
    }
    .app-black-base-text span {
        position: absolute;
    }
    .add-competitor-button {
        margin-top: 12px;
        width: 330px;
        color: #fff;
        height: 50px;
        background: #FF6969;
        border-radius: 30px;
        border: 0;
        box-shadow: 0 5px 10px #FF696964;
        color: #fff;
        font-size: 14px;
        bottom: 0;
        text-transform: uppercase;
        line-height: 50px;
        margin-left: 24px;
        position: relative;
    }
    .appButton-right-icon {
        background: #fff;
        width: 28px;
        height: 28px;
        border: none;
        border-radius: 30px;
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .appButton-right-icon img {
        width: 7px;
        margin-bottom: 23px;
    }
    .your-competitor-container {
        display: inline-block;
        margin-top: 12px;
        width: 350px;
        height: 36px;
        background: #D4D6DC;
        border-radius: 10px;
        border: 0;
        box-shadow: 0 5px 10px #00000016;
        color: #515c6f;
        font-size: 14px;
        position: relative;
        margin-left: 15px;
    }
    .your-comp-left-icon {
        display: inline-block;
        width: 30px;
        height: 22px;
        text-align: center;
        margin-top: 5px;
        margin-left: 10px;
    }
    .web-address-entered-container {
        display: inline-block;
        background: #D4D6DC;
        height: 36px;
        width: calc(100% - 75px);
    }
    .web-address-entered {
        width: 100%;
        font-size: 13px;
        line-height: 34px;
        text-transform: uppercase;
        color: #515C6F;
    }
    .your-comp-right-icon {
        display: inline-block;
        width: 30px;
        height: 22px;
        text-align: center;
        margin-top: 5px;
        margin-right: 10px;
        position: absolute;
    }
    .add-button {
        width: 65px;
        height: 65px;
        background: #352641;
        border-radius: 40px;
        border: 0;
        bottom:40px;
        margin-left: 24px;
        position: fixed;
        right: 20px;
    }
}