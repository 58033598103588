.dashboard-text {
  padding: 0 0 0 0 !important;
  max-height: 135px;
}
.dashboard-chart {
  padding: 0 0 40px 0 !important;
  overflow-x: auto;
  max-height: 400px;
  /* -moz- (Firefox): */
  scrollbar-width:none;
  /* -ms- (Internet Explorer +10): */
  -ms-overflow-style: none;
}
.dashboard-chart-slider {
  /* min-width: 1153px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.dashboard-chart::-webkit-scrollbar {
  /* safari , chrome */
  -webkit-appearance: none;
  width: 0 !important;
  height: 0 !important;
 }

.all-tasks-chart {
  padding: 0 0 0 0 !important;
  min-width: 500px;
}
.all-tasks-chart-label {
  text-align: center;
  font-size: 15px;
  font-weight: 300;
}
.tag-tasks-chart {
  padding: 0 0 0 0 !important;
  min-width: 17%;
}
