.task-list {
  display: flex;
}

.checkbox-div {
  margin-top: 25px;
  margin-left: 20px;
  border-radius: 25%;
}

.bulk-button-div {
  display: flex;
  margin-top: 20px;
}

.tasklist-div {
  width: 100%;
  padding: 5px 10px 5px 10px;
}

.Filter-Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Priority-Header {
  align-items: flex-end;
  padding: 0 10px;
}
.bulk-actions {
  display: flex;
}
@media screen and (max-width: 572px) {
  .bulk-actions {
    display: inline;
  }
  .Filter-Header {
    display: flex;
    flex-direction: row;
  }
}

.select-button {
  justify-content: flex-start;
  padding: 2px 2px;
  /* color: #ff6969; */
}

.buttonSelect {
  /* color: #ff6969;s */
  box-shadow: 0 1px 2px #d3d3d3;
}

.applyButton {
  display: "block";
  margin-top: 20px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 2px 12px;
}